<template>
  <section class="concept">
    <div class="concept__background">
      <h1 class="concept__background__title">湯沢の食を共有したい</h1>
      <p class="concept__background__text">
        秋田県、湯沢市<br>
        豪雪地として知られるこの地域では<br>
        冬の厳しさを乗り越え、独自の食文化を育んでいます<br>
        <br>
        各種山菜から、いちご、枝豆、りんご、せり……<br>
        ひろっこ、ふくたち、ちょろぎ、など<br>
        ちょっと珍しい野菜まで<br>
        <br>
        私たちの生活に関わる食を、農産物の販売を通して<br>
        まったりと皆さんに共有できればと思っております
      </p>
    </div>
  </section>
</template>

<script>
import { resrcUrl } from '../../utils/helper'
import { withUrl } from '../../utils/style'

export default {
  name: 'AppConcept',
  data() {
    // 画像サイズについて
    // imageUrl: 375x437
    // imageWideUrl: 1280x648
    return {
      imageUrl: withUrl(resrcUrl('about.jpg')),
      imageWideUrl: withUrl(resrcUrl('about-wide.jpg'))
    }
  }
}
</script>

<style lang="scss" scoped>
.concept {
  display: flex;
  align-items: center;
  height: $main-image-size;
  background-image: v-bind(imageUrl);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &__background {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 76.6%;
    background-color: rgba(0, 0, 0, 0.666);
    box-shadow: 0px 0px 8px 24px rgba(0, 0, 0, 0.666);
    &__title {
      font-size: 2.8rem;
      color: $font-color-flipped;
    }
    &__text {
      margin-top: 16px;
      font-size: 1.4rem;
      color: $font-color-flipped;
      line-height: 2.6rem;
      text-align: center;
    }
  }
}

@media screen and (min-width: $min-width-threshold) {

.concept {
  height: $main-image-size-wide;
  background-image: v-bind(imageWideUrl);
  &__background {
    height: 82%;
    &__title {
      font-size: 4.2rem;
    }
    &__text {
      margin-top: 32px;
      font-size: 2.0rem;
      line-height: 4.0rem;
    }
  }
}

}
</style>
