<template>
  <div class="mycarousel">
    <Carousel :autoplay="3000" :wrapAround="true" :pauseAutoplayOnHover="true">
      <Slide v-for="s in slides" :key="s.imageUrl">
        <a class="mycarousel__link" :href="s.linkUrl">
          <img class="mycarousel__link__img" :src="s.imageUrl" :alt="s.title">
        </a>
        <a class="mycarousel__linkwide" :href="s.linkWideUrl">
          <img class="mycarousel__linkwide__imgwide" :src="s.imageWideUrl" :alt="s.title">
        </a>
        <div v-if="s.title" class="mycarousel__title">
          <h2 class="mycarousel__title__text" :class="titleClass(s.size)">{{ s.title }}</h2>
        </div>
        <div v-if="s.description" class="mycarousel__description">
          <p class="mycarousel__description__text">{{ s.description }}</p>
        </div>
      </Slide>
      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import { resrcName } from '../../utils/helper'
import { withModifier } from '../../utils/style'

export const Constants = {
  Size: {
    Small: 'small',
    Large: 'large'
  }
}

export default {
  name: 'MyCarousel',
  components: { Carousel, Pagination, Slide },
  props: {
    slides: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    imageName(uri) {
      return resrcName(uri)
    },
    titleClass(size) {
      size = size || Constants.Size.Large
      return [
        withModifier('mycarousel__title__text', size)
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.mycarousel {
  &__link {
    display: initial;
    width: 100%;
    height: $main-image-size;
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__linkwide {
    display: none;
    width: 100%;
    height: $main-image-size-wide;
    &__imgwide {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title {
    position: absolute;
    top: 0;
    left: 0;
    &__text {
      margin: 40px $content-space 0 $content-space;
      color: $font-color-flipped;
      text-shadow: 0px 0px 4px $font-color;
      opacity: 83.3%;
      text-align: start;
      white-space: pre-wrap;
      &--large {
        font-size: 6.4rem;
      }
      &--small {
        font-size: 4.2rem;
        line-height: 5.4rem;
      }
    }
  }
  &__description {
    position: absolute;
    bottom: 0;
    left: 0;
    &__text {
      margin: 0 $content-space 64px $content-space;
      font-size: 1.8rem;
      color: $font-color-flipped;
      text-shadow: 0px 0px 8px $font-color;
      opacity: 83.3%;
      line-height: 2.4rem;
      text-align: start;
      white-space: pre-wrap;
    }
  }
}

@media screen and (min-width: $min-width-threshold) {

.mycarousel {
  &__link {
    display: none;
  }
  &__linkwide {
    display: initial;
  }
  &__title {
    &__text {
      margin: 52px calc($content-space-wide + 40px) 0 calc($content-space-wide + 40px);
      &--large {
        font-size: 11.2rem;
      }
      &--small {
        font-size: 9.2rem;
        line-height: 11.2rem;
      }
    }
  }
  &__description {
    &__text {
      margin: 0 calc($content-space-wide + 40px) 108px calc($content-space-wide + 40px);
      font-size: 3.8rem;
      line-height: 4.8rem;
    }
  }
}

}
</style>
