<template>
  <textarea class="textarea" :class="textareaClass"
    :name="name" :value="value" :style="style"
    v-bind="attr"
    @change="onChange" @input="onInput">
  </textarea>
</template>

<script>
import { withModifier } from '../../utils/style'

export const Constants = {
  Effect: {
    Normal: 'normal',
    Error: 'error'
  }
}

export default {
  name: 'MyTextArea',
  props: {
    name: String,
    value: String,
    attr: Object,
    effect: {
      type: String,
      default: Constants.Effect.Normal,
      validator: v => Object.values(Constants.Effect).includes(v)
    },
    style: Object
  },
  methods: {
    onChange(e) {
      this.$emit('onChange', e)
    },
    onInput(e) {
      this.$emit('onInput', e)
    }
  },
  computed: {
    textareaClass() {
      return [
        withModifier('textarea', this.effect),
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.textarea {
  padding: 12px 12px;
  border-radius: 8px;
  font-size: 1.6rem;
  font-weight: normal;
  color: $font-color;
  &::placeholder {
    color: #aaa;
  }
  &--normal {
    border: 1px solid $border-color;
  }
  &--error {
    border: 1px solid $border-color-danger;
    background-color: $danger-color-weak;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
