import { createApp } from 'vue'
import VueScrollTo from 'vue-scrollto'
import MyIndicator from './components/Atoms/MyIndicator.vue'
import App from './App.vue'
import router from './router'

createApp(App)
  .use(router)
  .use(VueScrollTo)
  .component('MyIndicator', MyIndicator)
  .mount('#app')
