import DeliveryTypes from '../../constants/deliveryTypes.json'
import DeliveryTimes from '../../constants/deliveryTimes.json'
import DeliveryDays from '../../constants/deliveryDays.json'
import PaymentTypes from '../../constants/paymentTypes.json'
import YuzawaZip from '../../constants/yuzawaZip.json'

/**
 * 湯沢地域かどうか
 * @param {string} zip1
 * @param {string} zip2
 * @returns {boolean}
 */
export function aroundYuzawa(zip1, zip2) {
  return (zip1 in YuzawaZip) && (zip2 in YuzawaZip[zip1])
}

/**
 * 直接引き渡し系の配送方法か
 * @param {number} value
 * @returns {boolean}
 */
export function isDirectDelivery(value) {
  return DeliveryTypes.some(d => d.isDirect && d.value === value)
}

/**
 * 宅配便かどうか
 * @param {number} value
 * @returns {boolean}
 */
export function isDelivery(value) {
  return DeliveryTypes.some(d => d.id === 'Delivery' && d.value === value)
}

/**
 * 直接支払い系の支払方法か
 * @param {number} value
 * @returns {boolean}
 */
export function isDirectPayment(value) {
  return PaymentTypes.some(d => d.onlyDirectDelivery && d.value === value)
}

/**
 * 配送方法の取得
 * @param {number} value
 * @returns {Object}
 */
export function getDeliveryType(value) {
  return DeliveryTypes.find(d => d.value === value)
}

/**
 * 支払方法の取得
 * @param {number} value
 * @returns {Object}
 */
export function getPaymentType(value) {
  return PaymentTypes.find(d => d.value === value)
}

/**
 * お届け希望時間の取得
 * @param {number} value
 * @returns {Object}
 */
export function getDeliveryTime(value) {
  return DeliveryTimes.find(d => d.value === value)
}

/**
 * お届け希望日の取得
 * @param {number} value
 * @returns {Object}
 */
export function getDeliveryDay(value) {
  return DeliveryDays.find(d => d.value === value)
}
