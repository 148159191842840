<template>
  <section class="section">
    <h2 class="section__title">{{ title }}</h2>
    <div class="section__content">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ThemeSection',
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
.section {
  &__title {
    padding: 4px $content-space;
    font-size: 3.0rem;
    color: $font-color-flipped;
    background-color: $main-color;
  }
  &__content {
    margin-top: 24px;
    padding: 0px calc($content-space + 4px);
  }
}

@media screen and (min-width: $min-width-threshold) {

.section {
  &__title {
    padding: 4px $content-space-wide;
    font-size: 4.0rem;
  }
  &__content {
    padding: 0px calc($content-space-wide + 8px);
  }
}

}
</style>
