<template>
  <section class="address">
    <h2 class="address__title">{{ title }}</h2>
    <div class="address__content">
      <div class="address__content__text">{{ address }}</div>
      <MyButton class="address__content__button" :title="buttonTitle" :type="buttonType" :size="myButtonSizeSmall" @on-click="onClick" />
    </div>
  </section>
</template>

<script>
import MyButton, { Constants as ButtonConstants, ConstantsMixin as ButtonConstantsMixin } from '../Atoms/MyButton.vue'

export const Constants = {
  Type: {
    New: 'new',
    Current: 'current',
    Renew: 'renew'
  }
}
export const ConstantsMixin = {
  computed: {
    addressBoxTypeNew() {
      return Constants.Type.New
    },
    addressBoxTypeCurrent() {
      return Constants.Type.Current
    },
    addressBoxTypeRenew() {
      return Constants.Type.Renew
    }
  }
}

export default {
  mixins: [ButtonConstantsMixin],
  name: 'AddressBox',
  components: { MyButton },
  props: {
    type: {
      type: String,
      default: Constants.Type.New,
      validator: v => Object.values(Constants.Type).includes(v)
    },
    address: String
  },
  methods: {
    onClick(e) {
      this.$emit('onClickButton', e)
    }
  },
  computed: {
    title() {
      return this.type === Constants.Type.Current ? '保存済のお届け先' : '今回のお届け先'
    },
    buttonTitle() {
      switch (this.type) {
        case Constants.Type.Current:
          return '削除する'
        case Constants.Type.Renew:
          return '上書する'
        case Constants.Type.New:
        default:
          return '保存する'
      }
    },
    buttonType() {
      return this.type === Constants.Type.Current ? ButtonConstants.Type.Secondary : ButtonConstants.Type.Primary
    }
  }
}
</script>

<style lang="scss" scoped>
.address {
  &__title {
    font-size: 1.7rem;
    color: $font-color;
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $main-color;
    margin-top: 4px;
    padding: 12px;
    &__text {
      font-size: 1.4rem;
      color: $font-color;
      max-width: 60%;
      white-space: pre-wrap;
    }
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
