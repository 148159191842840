<template>
  <FormSection class="form" title="お届け先住所" required>
    <InputSection class="form__input" label="郵便番号">
      <div class="form__input__zipcode">
        <div class="form__input__zipcode__zip1">
          <MyInput class="form__input__zipcode__zip1__val" 
            name="zip1" maxlength="3" :value="zip1" :effect="zipEffect"
            @on-input="onInputZip1" @on-change="onChangeZip1" />
        </div>
        <div class="form__input__zipcode__zip2">
          <MyInput class="form__input__zipcode__zip2__val"
            ref="zip2" name="zip2" :value="zip2" maxlength="4" :effect="zipEffect"
            @on-input="onInputZip2" @on-change="onChangeZip2" />
        </div>
      </div>
      <MyError class="form__input__error" :messages="zipErrors" />
    </InputSection>
    <InputSection class="form__input" label="都道府県">
      <MySelectBox class="form__input__prefecture"
        name="prefecture" :value="prefecture" :data="prefectures" :effect="prefectureEffect"
        @on-change="onChangePrefecture" />
      <MyError class="form__input__error" :messages="prefectureErrors" />
    </InputSection>
    <InputSection class="form__input" label="住所">
      <MyInput class="form__input__box"
        name="address" :value="address" :effect="addressEffect"
        @on-input="onInputAddress" @on-change="onChangeAddress" />
      <MyError class="form__input__error" :messages="addressErrors" />
    </InputSection>
    <InputSection class="form__input" label="建物名">
      <MyInput class="form__input__box"
        name="building" :value="building" :effect="buildingEffect"
        @on-input="onInputBuilding" @on-change="onChangeBuilding" />
      <MyError class="form__input__error" :messages="buildingErrors" />
    </InputSection>
  </FormSection>
</template>

<script>
import Prefectures from '../../../constants/prefectures.json'
import { fetchAddress } from '../../utils/helper'
import FormSection from '../Atoms/FormSection.vue'
import InputSection from '../Atoms/InputSection.vue'
import MyError from '../Atoms/MyError.vue'
import MyInput, { Constants as MyInputConstants } from '../Atoms/MyInput.vue'
import MySelectBox, { Constants as MySelectBoxConstants } from '../Atoms/MySelectBox.vue'

export default {
  name: 'AddressForm',
  components: { FormSection, InputSection, MyError, MyInput, MySelectBox },
  props: {
    zip1: String,
    zip2: String,
    prefecture: String,
    address: String,
    building: String,
    zipErrors: {
      type: Array,
      default: () => []
    },
    prefectureErrors: {
      type: Array,
      default: () => []
    },
    addressErrors: {
      type: Array,
      default: () => []
    },
    buildingErrors: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onInputZip1(e) {
      if (e.target.value.length >= 3) {
        this.$refs.zip2.focus()
      }
      this.$emit('onInputZip1', e.target.value)
    },
    onChangeZip1(e) {
      this.$emit('onChangeZip1', e.target.value)
    },
    onInputZip2(e) {
      this.$emit('onInputZip2', e.target.value)
    },
    onChangeZip2(e) {
      this.$emit('onChangeZip2', e.target.value)
      if (this.zip1.length === 3 && e.target.value.length === 4) {
        fetchAddress(this.zip1 + e.target.value)
          .then(response => {
            const result = response.data.results[0]
            this.$emit('onChangePrefecture', result.address1)
            this.$emit('onInputAddress', result.address2 + result.address3)
          })
          .catch(err => {
            console.error(err)
          })
      }
    },
    onChangePrefecture(e) {
      this.$emit('onChangePrefecture', e.target.value)
    },
    onInputAddress(e) {
      this.$emit('onInputAddress', e.target.value)
    },
    onChangeAddress(e) {
      this.$emit('onChangeAddress', e.target.value)
    },
    onInputBuilding(e) {
      this.$emit('onInputBuilding', e.target.value)
    },
    onChangeBuilding(e) {
      this.$emit('onChangeBuilding', e.target.value)
    }
  },
  computed: {
    zipEffect() {
      return !this.zipErrors || this.zipErrors.length === 0 ?
        MyInputConstants.Effect.Normal : MyInputConstants.Effect.Error
    },
    prefectureEffect() {
      return !this.prefectureErrors || this.prefectureErrors.length === 0 ?
        MySelectBoxConstants.Effect.Normal : MySelectBoxConstants.Effect.Error
    },
    addressEffect() {
      return !this.addressErrors || this.addressErrors.length === 0 ?
        MyInputConstants.Effect.Normal : MyInputConstants.Effect.Error
    },
    buildingEffect() {
      return !this.buildingErrors || this.buildingErrors.length === 0 ?
        MyInputConstants.Effect.Normal : MyInputConstants.Effect.Error
    },
    prefectures() {
      return Prefectures;
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  &__input {
    &:not(:first-child) {
      margin-top: 16px;
    }
    &__zipcode {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &__zip1 {
        &:after {
          content: "ー";
          padding: 0 8px;
        }
        &__val {
          width: 72px;
        }
      }
      &__zip2 {
        &__val {
          width: 110px;
        }
      }
    }
    &__prefecture {
      width: 110px;
    }
    &__box {
      width: 100%;
    }
    &__error {
      margin-top: 8px;
    }
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
