<template>
  <transition name="fade">
    <div v-if="visible" class="dialog">
      <div class="dialog__box">
        <p class="dialog__box__text" >{{ message }}</p>
        <div class="dialog__box__button">
          <CrossButton class="dialog__box__button__close" @on-click="hide" />
        </div>
      </div>
      <div class="dialog__background" @click="hide"></div>
    </div>
  </transition>
</template>

<script>
import CrossButton from '../Atoms/CrossButton.vue'

export default {
  name: 'MyDialog',
  components: { CrossButton },
  data() {
    return {
      visible: false,
      message: ''
    }
  },
  methods: {
    show(message) {
      this.visible = true
      this.message = message
    },
    hide() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &__box {
    background-color: $base-color;
    width: 256px;
    padding: 24px 16px;
    z-index: $dialog-z-index;
    &__text {
      font-size: 1.6rem;
      color: $font-color;
      white-space: pre-wrap;
    }
    &__button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 8px;
      &__close {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: calc(#{$dialog-z-index} - 1);
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.fade {
  &-enter-active, &-leave-active {
    transition: opacity 0.75s ease;
  }
  &-enter-from, &-leave-to {
    opacity: 0;
  }
}

@media screen and (min-width: $min-width-threshold) {

.dialog {
  &__box {
    width: 320px;
  }
}

}
</style>
