<template>
  <button class="icon" @click="onClick">
    <img class="icon__img" src="../../assets/line.png" alt="Lineシェア">
  </button>
</template>

<script>
/**
 * LineシェアURLの作成
 * @param {string} url
 * @returns {string}
 */
function makeUrl(url) {
  const shareUrl = 'https://social-plugins.line.me/lineit/share'
  const queryString = new URLSearchParams([['url', url]]).toString()
  return shareUrl + '?' + queryString
}

export default {
  name: 'LineIcon',
  methods: {
    onClick() {
      const url = location.href
      window.open(makeUrl(url))
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  &__img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
