const Cache = {
  /**
   * キャッシュストア
   * @type {Object}
   */
  store: {},
  /**
   * キャッシュ時間
   * @type {number}
   */
  cacheTime: 60 * 1000 // 60秒
}

class CacheValue {
  /**
   * @type {any} キャッシュバリュー
   */
  #value
  /**
   * @type {number} キャッシュ時間（unixtimeミリ秒）
   */
  #cacheTimeOver

  /**
   * @param {any} value 値
   */
  constructor(value) {
    this.#value = value
    this.#cacheTimeOver = new Date().getTime() + Cache.cacheTime
  }

  /**
   * @returns {any}
   */
  get value() {
    return this.#value
  }

  /**
   * キャッシュタイムオーバーか
   * @returns {boolean}
   */
  get isOver() {
    return this.#cacheTimeOver <= new Date().getTime()
  }
}

/**
 * パスが存在するか
 * @param {string} path
 * @param {string} id
 * @returns {boolean}
 */
function hasValue(path, id) {
  return (path in Cache.store) && (id in Cache.store[path]);
}

/**
 * キャッシュの設定
 * @param {string} path
 * @param {string} id
 * @param {any} value
 */
export function setCache(path, id, value) {
  Cache.store[path] = {}
  Cache.store[path][id] = new CacheValue(value)
}

/**
 * キャッシュの存在有無
 * @param {string} path
 * @param {string} id
 * @returns {boolean}
 */
export function hasCache(path, id) {
  if (!hasValue(path, id)) {
    return false
  }
  const container = Cache.store[path]
  if (container[id].isOver) {
    delete container[id]
    return false
  }
  return true
}

/**
 * キャッシュの取得
 * @param {string} path
 * @param {string} id
 * @returns {any} value
 */
export function getCache(path, id) {
  if (!hasValue(path, id)) {
    return null
  }
  const container = Cache.store[path]
  if (container[id].isOver) {
    delete container[id]
    return null
  }
  return container[id].value
}

/**
 * 全キャッシュクリア
 */
export function clearCache() {
  Cache.store = {}
}
