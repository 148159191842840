<template>
  <div class="view">
    <p class="view__error">ページが存在しません。</p>
  </div>
</template>

<script>
export default {
  name: 'ErrorView'
}
</script>

<style lang="scss" scoped>
.view {
  &__error {
    margin-top: 100px;
    font-size: 1.5rem;
    color: $font-color;
    line-height: 3.0rem;
    text-align: center;
  }
}

@media screen and (min-width: $min-width-threshold) {

.view {
  &__error {
    margin-top: 124px;
    font-size: 1.7rem;
  }
}

}
</style>
