<template>
  <div class="description">
    <img class="description__img" :src="image">
    <p class="description__text">{{ description }}</p>
  </div>
</template>

<script>
import { resrcName } from '../../utils/helper'

export default {
  name: 'PlanDescription',
  props: {
    image: String,
    description: String
  },
  methods: {
    imageName(uri) {
      return resrcName(uri)
    }
  }
}
</script>

<style lang="scss" scoped>
.description {
  &__img {
    width: 100%;
    object-fit: fill;
  }
  &__text {
    margin-top: 16px;
    padding: 0 4px 0 4px;
    font-size: 1.5rem;
    color: $font-color;
    line-height: 2.0rem;
    text-align: start;
    white-space: pre-wrap;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
