<template>
  <FormSection class="form" title="支払方法" required>
    <div v-for="paymentType in paymentTypes" class="form__radio" :key="paymentType.value">
      <MyRadio v-if="displayed(paymentType)"
        name="paymentType" :value="String(paymentType.value)" :label="paymentType.name" :checked="isChecked(1)"
        @on-change="onChage" />
    </div>
    <MyError class="form__error" :messages="errors" />
    <MyNote class="form__note" :type="myNoteTypeKome" :data="description" />
  </FormSection>
</template>

<script>
import PaymentTypes from '../../../constants/paymentTypes.json'
import FormSection from '../Atoms/FormSection.vue'
import { getPaymentType } from '../../utils/constants.js'
import MyError from '../Atoms/MyError.vue'
import MyRadio from '../Atoms/MyRadio.vue'
import MyNote, { ConstantsMixin as MyNoteConstantsMixin } from '../Atoms/MyNote.vue'

export default {
  mixins: [MyNoteConstantsMixin],
  name: 'PaymentTypeForm',
  components: { FormSection, MyError, MyRadio, MyNote },
  props: {
    value: Number,
    isDirectDelivery: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    isChecked(value) {
      this.value === value
    },
    displayed(paymentType) {
      // 直接引き渡しのみフラグがfalseならば常に表示
      return !paymentType.onlyDirectDelivery ||
        (this.isDirectDelivery && paymentType.onlyDirectDelivery)
    },
    onChage(e) {
      this.$emit('onChangePaymentType', Number(e.target.value))
    }
  },
  computed: {
    paymentTypes() {
      return PaymentTypes
    },
    description() {
      const payment = getPaymentType(this.value)
      if (!payment) {
        return []
      }
      const r = []
      if (payment.id === 'BankTransfer') {
        r.push('銀行振込時の手数料はお客さま負担となります。')
      }
      if (this.isDirectDelivery) {
        r.push('現金支払いは配送方法が直接引き渡しの場合のみ利用可能です。')
      }
      return r
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  &__radio {
    &:not(:first-child) {
      margin-top: 6px;
    }
  }
  &__error {
    margin-top: 8px;
  }
  &__note {
    margin-top: 8px;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
