/**
 * cssモディフィア
 * @param {string} path
 * @returns {string}
 */
export function withModifier(className, modifier) {
  return className + '--' + modifier
}

/**
 * cssでのurl表現
 * @param {string} path
 * @returns {string}
 */
export function withUrl(url) {
  return 'url(' + url + ')'
}
