<template>
  <footer class="footer">
    <div class="footer__content">
      <ul class="footer__content__links">
        <li class="footer__content__links__item"><MyLink class="footer__content__links__item__link" to="/about" title="このサイトについて" /></li>
        <li class="footer__content__links__item"><MyLink class="footer__content__links__item__link" to="/policy" title="サイトポリシー" /></li>
        <li class="footer__content__links__item"><MyLink class="footer__content__links__item__link" to="/jaosct" title="特定商取引法に基づく表記" /></li>
      </ul>
      <div class="footer__content__misc">
        <ul v-if="withSocial" class="footer__content__misc__social">
          <li class="footer__content__misc__social__item"><TwitterIcon /></li>
          <li class="footer__content__misc__social__item"><FacebookIcon /></li>
          <li class="footer__content__misc__social__item footer__content__misc__social__item--line"><LineIcon /></li>
        </ul>
        <div class="footer__content__misc__info">
          <AppLogo class="footer__content__misc__info__img" />
          <div class="footer__content__misc__info__copyright">©︎ 2023 YMGT 山形優二 All rights reserved</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import AppLogo from '../Atoms/AppLogo.vue'
import MyLink from '../Atoms/MyLink.vue'
import TwitterIcon from '../Atoms/TwitterIcon.vue'
import FacebookIcon from '../Atoms/FacebookIcon.vue'
import LineIcon from '../Atoms/LineIcon.vue'

export default {
  name: 'AppFooter',
  components: { AppLogo, MyLink, TwitterIcon, FacebookIcon, LineIcon },
  props: {
    withSocial: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  margin-top: 64px;
  border-top: 1px solid $main-color;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px 0 16px 0;
    &__links {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__item {
        &__link {
          font-size: 1.5rem;
        }
        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }
    &__misc {
      &__social {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
        &__item {
          &:not(:first-child) {
            margin-left: 16px;
          }
        }
      }
      &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;
        &__img {
          display: block;
        }
        &__copyright {
          margin-top: 16px;
          font-size: 1.2rem;
        }
      }
    }
  }
}

@media screen and (min-width: $min-width-threshold) {

.footer {
  &__content {
    margin: 64px $content-space-wide 96px $content-space-wide;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    &__links {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      &__item {
        &:not(:first-child) {
          margin-top: 0;
          margin-left: 32px;
        }
      }
    }
    &__misc {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      &__social {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 0;
        &__item {
          &:not(:first-child) {
            margin-left: 16px;
          }
          &--line {
            display: none;
          }
        }
      }
      &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        &__img {
          display: block;
        }
        &__copyright {
          margin-top: 16px;
          font-size: 1.3rem;
        }
      }
    }
  }
}

}
</style>
