<template>
  <FormSection class="form" title="お届け先氏名">
    <MyInput class="form__input" name="deliveryName" :value="value" :effect="inputEffect" @on-input="onInput" @on-change="onChange" />
    <MyError class="form__error" :messages="errors" />
    <MyNote class="form__note" :type="myNoteTypeKome" :data="description" />
  </FormSection>
</template>

<script>
import FormSection from '../Atoms/FormSection.vue'
import MyError from '../Atoms/MyError.vue'
import MyInput, { Constants as MyInputConstants } from '../Atoms/MyInput.vue'
import MyNote, { ConstantsMixin as MyNoteConstantsMixin } from '../Atoms/MyNote.vue'

export default {
  mixins: [MyNoteConstantsMixin],
  name: 'DeliveryNameForm',
  components: { FormSection, MyError, MyInput, MyNote },
  props: {
    value: String,
    errors: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onInput(e) {
      this.$emit('onInputDeliveryName', e.target.value)
    },
    onChange(e) {
      this.$emit('onChangeDeliveryName', e.target.value)
    }
  },
  computed: {
    inputEffect() {
      return !this.errors || this.errors.length === 0 ?
        MyInputConstants.Effect.Normal : MyInputConstants.Effect.Error
    },
    description() {
      return ['未入力の場合はご注文者名でお届けいたします。']
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  &__input {
    width: 100%;
  }
  &__error {
    margin-top: 8px;
  }
  &__note {
    margin-top: 8px;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
