import { createRouter, createWebHistory } from 'vue-router'
import AboutView from './components/Containers/AboutView.vue'
import CompleteView from './components/Containers/CompleteView.vue'
import ErrorView from './components/Containers/ErrorView.vue'
import JAoSCTView from './components/Containers/JAoSCTView.vue'
import OrderView from './components/Containers/OrderView.vue'
import PlanView from './components/Containers/PlanView.vue'
import PolicyView from './components/Containers/PolicyView.vue'
import TopView from './components/Containers/TopView.vue'
import config from '../configs/yamanota.json'

const routes = [
  {
    path: '/',
    name: 'Top',
    component: TopView,
    meta: {
      hasMeta: true,
      withSocial: true,
      ...config
    }
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView,
    meta: {
      hasMeta: true,
      title: 'このサイトについて | やまのた直売所',
      description: null,
      withSocial: true
    }
  },
  {
    path: '/policy',
    name: 'Policy',
    component: PolicyView,
    meta: {
      hasMeta: true,
      title: 'サイトポリシー | やまのた直売所',
      description: null,
      withSocial: false
    }
  },
  {
    path: '/jaosct',
    name: 'JAoSCT',
    component: JAoSCTView,
    meta: {
      hasMeta: true,
      title: '特定商取引法に基づく表記 | やまのた直売所',
      description: null,
      withSocial: false
    }
  },
  {
    path: '/plan/:planId',
    name: 'Plan',
    component: PlanView,
    props: true,
    meta: {
      withSocial: true
    }
  },
  {
    path: '/plan/:planId/order',
    name: 'Order',
    component: OrderView,
    props: true,
    meta: {
      withSocial: false,
      noindex: true,
    }
  },
  {
    path: '/complete/:orderId',
    name: 'Complete',
    component: CompleteView,
    props: true,
    meta: {
      withSocial: false,
      noindex: true,
      nofollow: true
    }
  },
  {
    // 最後の*（もしくは+）は/で区切られる複数のセクションにマッチするという意味
    path: '/:path(.*)*',
    component: ErrorView,
    meta: {
      hasMeta: true,
      title: 'エラー | やまのた直売所',
      description: null,
      withSocial: false,
      noindex: true,
      nofollow: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  }
})

export default router
