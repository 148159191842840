import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics'

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
}

const app = initializeApp(config)

export const firestore = getFirestore(app)
export const functions = getFunctions(app, 'asia-northeast1')
export const analytics = getAnalytics(app)
// developmentの場合はエミュレーターに対してリクエストを発行
if (process.env.NODE_ENV === 'development') {
  connectFirestoreEmulator(firestore, 'localhost', 8081)
  connectFunctionsEmulator(functions, 'localhost', 5001)
}
