<template>
  <aside v-if="hasError" class="error">
    <ul>
      <li class="error__item" v-for="(message, index) in messages" :key="index">{{ message }}</li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: 'MyError',
  props: {
    messages: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasError() {
      return this.messages && this.messages.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  &__item {
    font-size: 13px;
    color: $font-color-danger;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
