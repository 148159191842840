<template>
  <ul class="menu">
    <li class="menu__item" v-for="plan in plans" :key="plan.id">
      <div v-if="plan.isDisabled" class="menu__item__text">{{ plan.name }}</div>
      <MyLink v-else class="menu__item__link" :to="plan.url" :title="plan.name" />
    </li>
  </ul>
</template>

<script>
import MyLink from '../Atoms/MyLink.vue'

export default {
  name: 'PopupItemMenu',
  components: { MyLink },
  props: {
    plans: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  &__item {
    &:not(:first-child) {
      margin-top: 12px;
    }
    &__text {
      font-size: 1.6rem;
      color: $font-color-weak;
    }
    &__link {
      font-size: 1.6rem;
    }
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
