<template>
  <div class="cover">
    <MyIndicator class="cover__indicator" />
  </div>
</template>

<script>
import MyIndicator from '../Atoms/MyIndicator.vue'

export default {
  components: { MyIndicator },
  name: 'IndicatorCover'
}
</script>

<style lang="scss" scoped>
.cover {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: calc(#{$indicator-z-index} - 1);
  background-color: rgba(255, 255, 255, 0.5);
  &__indicator {
    width: 128px;
    height: 128px;
    z-index: $indicator-z-index;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
