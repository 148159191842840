import Model from './Model'

/**
 * 品目
 * /item/{itemId}
 */
export default class Item extends Model {
  /**
   * パス
   * @type {string}
   */
  static path = '/year/:year/items';

  /**
   * 品目名
   * @type {string}
   */
  name
  /**
   * 説明文
   * @type {string}
   */
  description
  /**
   * 品目画像URL（SP）
   * 画像サイズ: 134x199
   * @type {string}
   */
  imageItemUrl
  /**
   * 品目画像URL（PC）
   * 画像サイズ: 338x189
   * @type {string}
   */
  imageItemWideUrl
  /**
   * 作成日
   * @type {DateTime}
   */
  createdAt

  /**
   * 表示説明文
   * @returns {string}
   */
  get displayedDescription() {
    return this.description.replaceAll('\\n', '\n');
  }
}
