<template>
  <div class="view">
    <IndicatorCover v-if="isLoading" />
    <div v-if="dataLoaded">
      <div class="view__breadcrumbs">
        <MyBreadcrumbs :links="breadcrumbs" />
      </div>
      <div class="view__content">
        <p class="view__content__id"><span class="view__content__id__label">ご注文ID</span>{{ data.order.id }}</p>
        <div class="view__content__message">
          <p class="view__content__message__text">
            ご注文ありがとうございます。<br>
            ご注文確認のメールをお送りしております。<br>
            商品の発送時期は<span class="view__content__message__text__period">{{ deliveryPeriod }}</span>を予定しております。<br>
            収穫状況によっては時期が前後する可能性がありますのでご了承ください。
          </p>
        </div>
        <OrderDetail class="view__content__detail" :data="orders" />
        <PaymentInfo class="view__content__payment"
          :ordered-at="orderedAt" :payment-type="data.order.paymentType" :confirm-code="data.order.confirmCode" />
        <p class="view__content__inquiry">
          お問い合わせは下記のアドレスまでお送りください。<br>
          yuji.yamagata2@gmail.com
        </p>
        <section v-if="newAddress" class="view__content__address">
          <h2 class="view__content__address__title">お届け先情報を保存しますか？</h2>
          <div class="view__content__address__block">
            <MyNote :data="notes" :type="myNoteTypeKome" />
            <div class="view__content__address__block__list">
              <transition name="fade">
                <AddressBox v-if="shouldDisplayNewAddress" class="view__content__address__block__list__box"
                  :type="newAddressBoxType" :address="newAddress" @on-click-button="onClickSave" />
              </transition>
              <transition name="fade">
                <AddressBox v-if="currentAddress" class="view__content__address__block__list__box"
                  :type="addressBoxTypeCurrent" :address="currentAddress" @on-click-button="onClickRemove" />
              </transition>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../firebase'
import Order from '../../models/Order'
import { NotFound } from '../../utils/errors'
import { setupMetaTags } from '../../utils/helper.js'
import MyNote, { ConstantsMixin as MyNoteConstantsMixin } from '../Atoms/MyNote.vue'
import IndicatorCover from '../Presentations/IndicatorCover.vue'
import MyBreadcrumbs from '../Presentations/MyBreadcrumbs.vue'
import OrderDetail from '../Presentations/OrderDetail.vue'
import AddressBox, { Constants as AddressBoxConstants, ConstantsMixin as AddressBoxConstantsMixin } from '../Presentations/AddressBox.vue'
import PaymentInfo from '../Presentations/PaymentInfo.vue'

/**
 * データの取得
 * @param {string} year
 * @param {string} planId
 * @returns {Promise<Object>}
 */
async function fetchData(orderId) {
  const secret = window.localStorage.getItem(orderId)
  if (!secret) {
    throw new NotFound()
  }
  const findOrder = httpsCallable(functions, 'findOrder')

  const result = await findOrder({ orderId, secret })
  const order = new Order(result.data)
  const plan = await order.getPlan()
  const group = await order.getProductGroup()
  const product = await order.getProduct()
  return { order, plan, group, product }
}

export default {
  mixins: [AddressBoxConstantsMixin, MyNoteConstantsMixin],
  name: 'CompleteView',
  components: {
    MyNote,
    IndicatorCover,
    MyBreadcrumbs,
    OrderDetail,
    AddressBox,
    PaymentInfo
  },
  props: {
    orderId: String,
    newAddress: String
  },
  data() {
    return {
      isLoading: true,
      data: null,
      currentAddress: null,
      dataLoaded: false
    }
  },
  methods: {
    onClickSave() {
      window.localStorage.setItem('currentAddress', this.newAddress)
      this.currentAddress = this.newAddress
    },
    onClickRemove() {
      window.localStorage.removeItem('currentAddress')
      this.currentAddress = null
    }
  },
  computed: {
    breadcrumbs() {
      return [
        { title: 'ホーム', to: '/' },
        { title: this.data.plan.name, to: this.data.plan.url },
        { title: '注文完了' }
      ]
    },
    orders() {
      const orders = []
      orders.push({
        item: this.data.group.name + ' ' + this.data.product.name,
        price: this.data.order.price
      })
      if (this.data.order.deliveryType === 1) {
        orders.push({
          item: '配送料金',
          price: this.data.order.deliveryPrice
        })
      }
      return orders
    },
    orderedAt() {
      return new Date(this.data.order.createdAt._seconds * 1000)
    },
    notes() {
      return [
        '内容はブラウザに保存されます。不特定多人数が使用するPC、スマホでのご使用の際はご注意ください。',
      ]
    },
    deliveryPeriod() {
      return this.data.group.deliveryPeriod
    },
    shouldDisplayNewAddress() {
      return this.newAddress !== this.currentAddress
    },
    newAddressBoxType() {
      return this.currentAddress ? AddressBoxConstants.Type.Renew : AddressBoxConstants.Type.New
    }
  },
  created() {
    fetchData(this.orderId)
        .then(data => {
          setupMetaTags('注文完了【' + data.plan.name + '】 | やまのた直売所')
          this.data = data
          this.currentAddress = window.localStorage.getItem('currentAddress')
          this.dataLoaded = true
          this.isLoading = false
        })
        .catch(e => {
          console.error(e)
          this.$router.replace('/404')
        })
  }
}
</script>

<style lang="scss" scoped>
.view {
  &__breadcrumbs {
    margin: 0 $content-space;
  }
  &__content {
    margin: 36px calc($content-space + 4px) 0 calc($content-space + 4px);
    &__id {
      font-size: 2.0rem;
      color: $font-color;
      text-align: center;
      &__label {
        font-size: 2.0rem;
        color: $font-color;
        &:after {
          content: '：';
        }
      }
    }
    &__message {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 16px;
      &__text {
        font-size: 1.5rem;
        color: $font-color;
        line-height: 3.0rem;
        &__period {
          margin: 0 8px;
          font-weight: bold;
        }
      }
    }
    &__detail {
      margin-top: 36px;
    }
    &__payment {
      margin-top: 36px;
    }
    &__inquiry {
      margin-top: 36px;
      font-size: 1.4rem;
      color: $font-color;
      line-height: 2.8rem;
      text-align: center;
    }
    &__address {
      margin-top: 52px;
      &__title {
        font-size: 1.7rem;
      }
      &__block {
        margin-top: 4px;
        padding: 0 4px;
        &__list {
          &__box {
            margin-top: 20px;
            opacity: 1.0;
          }
        }
      }
    }
  }
}

.fade {
  &-enter-active {
    transition: opacity 0.75s ease;
  }
  &-enter-from, &-leave-to {
    opacity: 0;
  }
}

@media screen and (min-width: $min-width-threshold) {

.view {
  &__breadcrumbs {
    margin: 0 $content-space-wide;
  }
  &__content {
    margin: 64px calc($content-space-wide + 8px) 0 calc($content-space-wide + 8px);
    &__message {
      &__text {
        font-size: 1.7rem;
        line-height: 3.4rem;
      }
    }
    &__detail {
      margin-left: auto;
      margin-right: auto;
      width: 343px;
    }
    &__payment {
      margin-left: auto;
      margin-right: auto;
      width: 343px;
    }
    &__inquiry {
      font-size: 1.6rem;
      line-height: 3.2rem;
    }
    &__address {
      &__title {
        font-size: 2.0rem;
      }
      &__block {
        &__list {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          &__box {
            margin-top: 0;
            width: 50%;
            &:not(:first-child) {
              margin-left: 24px;
            }
          }
        }
      }
    }
  }
}

}
</style>
