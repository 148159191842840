import axios from 'axios'
import config from '../../configs/yamanota.json'

/**
 * リソースサーバーから指定のリソースを取得
 * @param {string} path
 * @returns {string}
 */
export function resrcUrl(path = '') {
  if (path.startsWith('/')) {
    path = path.substring(1)
  }
  return process.env.VUE_APP_RESOURCE_URL_PREFIX + path
}

/**
 * URIの名前部分を取得
 * @param {string} uriString
 * @returns {string}
 */
export function resrcName(uri) {
  const url = new URL(uri)
  return url.pathname.replace(/^([^/]*\/)*/, '')
}

/**
 * 年月日フォーマットに変換
 * @param {Date} d
 * @returns {string}
 */
export function formatJpDate(date) {
  const y = date.getFullYear()
  const m = date.getMonth() + 1
  const d = date.getDate()
  return `${y}年${m}月${d}日`
}

/**
 * yyyy/mm/dd HH:ii:ssフォーマットに変換
 * @param {Date} d
 * @returns {string}
 */
export function formatDateTime(date) {
  const y = date.getFullYear()
  const m = ('0' + (date.getMonth() + 1)).slice(-2)
  const d = ('0' + date.getDate()).slice(-2)
  const h = ('0' + date.getHours()).slice(-2)
  const i = ('0' + date.getMinutes()).slice(-2)
  const s = ('0' + date.getSeconds()).slice(-2)
  return `${y}/${m}/${d} ${h}:${i}:${s}`
}

/**
 * 年月日を数値に変換
 * @param {Date} d
 * @returns {number}
 */
export function dateToYmdNumber(d) {
  return (d.getFullYear() * 10000) + (d.getMonth() + 1) * 100 + d.getDate()
}

/**
 * 数値年月日をDate型に変換
 * @param {number} ymd
 * @returns {Date}
 */
export function ymdNumberToDate(ymd) {
  const d = new Date()
  d.setFullYear(Math.floor(ymd / 10000))
  d.setMonth(Math.floor(ymd / 100) % 100 - 1)
  d.setDate(ymd % 100)
  return d
}

/**
 * 値が空か
 * @param {any} value
 * @returns {boolean}
 */
export function isEmpty(value) {
  return !value || (Array.isArray(value) && value.length === 0)
}

/**
 * メタタグを設定
 * @param {string} title
 * @param {string} description
 * @param {array} keywords
 */
export function setupMetaTags(title, description, keywords = []) {
  // タイトル系の書き換え
  title = title || config.title
  document.title = title
  document.querySelector('meta[property="og:title"]').setAttribute('content', title)
  document.querySelector('meta[name="twitter:title"]').setAttribute('content', title)
  // 説明文の書き換え
  description = description || config.description
  document.querySelector('meta[name="description"]').setAttribute('content', description)
  document.querySelector('meta[property="og:description"]').setAttribute('content', description)
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', description)
  // キーワード
  keywords = config.keywords.concat(keywords)
  document.querySelector('meta[name="keywords"]').setAttribute('content', keywords.join(','))
}

/**
 * ロボットメタを設定
 * @param {boolean} noindex
 * @param {boolean} nofollow
 */
export function setupMetaRobots(noindex, nofollow) {
  const robots = []
  if (noindex) {
    robots.push('noindex')
  }
  if (nofollow) {
    robots.push('nofollow')
  }
  document.querySelector('meta[name="robots"]').setAttribute('content', robots.join(','))
}

/**
 * 郵便番号から住所を取得する
 * @param {string} zip
 * @returns {Promise<Object>}
 */
export async function fetchAddress(zip) {
  const queryString = new URLSearchParams([['zipcode', zip]]).toString()
  return await axios.get(process.env.VUE_APP_ZIPCODE_URL + '?' + queryString)
}
