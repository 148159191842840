<template>
  <div class="view">
    <div class="view__breadcrumbs">
      <MyBreadcrumbs :links="breadcrumbs" />
    </div>
    <div class="view__content">
      <h1 class="view__content__title">特定商取引法に基づく表記</h1>
      <div class="view__content__body">
        <table class="view__content__body__table">
          <tbody>
            <tr class="view__content__body__table__row">
              <th class="view__content__body__table__row__head view__content__body__table__row__head--none">販売業者名</th>
              <td class="view__content__body__table__row__data">山形優二</td>
            </tr>
            <tr class="view__content__body__table__row">
              <th class="view__content__body__table__row__head">販売責任者</th>
              <td class="view__content__body__table__row__data">山形優二</td>
            </tr>
            <tr class="view__content__body__table__row">
              <th class="view__content__body__table__row__head">所在地</th>
              <td class="view__content__body__table__row__data">
                秋田県湯沢市
                <aside class="view__content__body__table__row__data__note">
                  問い合わせメールアドレスにてご請求をいただければ、遅滞なく開示いたします。
                </aside>
              </td>
            </tr>
            <tr class="view__content__body__table__row">
              <th class="view__content__body__table__row__head">電話番号</th>
              <td class="view__content__body__table__row__data">
                ー
                <aside class="view__content__body__table__row__data__note">
                  問い合わせメールアドレスにてご請求をいただければ、遅滞なく開示いたします。
                </aside>
              </td>
            </tr>
            <tr class="view__content__body__table__row">
              <th class="view__content__body__table__row__head">メールアドレス</th>
              <td class="view__content__body__table__row__data">yuji.yamagata2@gmail.com</td>
            </tr>
            <tr class="view__content__body__table__row">
              <th class="view__content__body__table__row__head">支払方法</th>
              <td class="view__content__body__table__row__data">
                銀行振込、現金支払い
                <aside class="view__content__body__table__row__data__note">
                  現金支払いは直接引き渡しの場合のみ利用可能です。
                </aside>
              </td>
            </tr>
            <tr class="view__content__body__table__row">
              <th class="view__content__body__table__row__head">商品引渡し時期</th>
              <td class="view__content__body__table__row__data">お客様の日時希望がない限り、収穫後１日以内に発送いたします。</td>
            </tr>
            <tr class="view__content__body__table__row">
              <th class="view__content__body__table__row__head">商品の金額について</th>
              <td class="view__content__body__table__row__data">商品及び商品以外の費用は、すべて消費税込みの金額です。</td>
            </tr>
            <tr class="view__content__body__table__row">
              <th class="view__content__body__table__row__head">商品以外の必要料金</th>
              <td class="view__content__body__table__row__data">
                送料はお客様負担にてお願いいたします。<br>
                なお、商品によって送料が異なりますので詳細は注文フォームにてご確認ください。<br>
                支払方法に銀行振込を指定した場合、手数料はお客さま負担となります。
              </td>
            </tr>
            <tr class="view__content__body__table__row">
              <th class="view__content__body__table__row__head">返品・キャンセルについて</th>
              <td class="view__content__body__table__row__data">
                お客様のご都合による商品の返品については、原則としてはお受けできません。<br>
                発送時送料・返品送料及びキャンセル品の決済手数料等をご負担頂きます。また、返品は未開封品に限ります。
              </td>
            </tr>
            <tr class="view__content__body__table__row">
              <th class="view__content__body__table__row__head">不良品について</th>
              <td class="view__content__body__table__row__data">
                商品の品質に問題があった場合、または、ご注文と異なる商品が届いた場合は返金の対応を受け付けます。
                問い合わせメールアドレスに商品の写真と共にご連絡をお願いいたします。<br>
                なお、対応可能な期限ですが、商品到着後７日間以内にご連絡ください。
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import MyBreadcrumbs from '../Presentations/MyBreadcrumbs.vue'

export default {
  name: 'JAoSCTView',
  components: { MyBreadcrumbs },
  computed: {
    breadcrumbs() {
      return [
        { title: 'ホーム', to: '/' },
        { title: '特定商取引法に基づく表記' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.view {
  &__breadcrumbs {
    margin: 0 $content-space;
  }
  &__content {
    margin: 8px calc($content-space + 4px) 0 calc($content-space + 4px);
    &__title {
      font-size: 2.6rem;
      color: $main-color;
    }
    &__body {
      margin-top: 24px;
      padding: 0 4px;
      &__table {
        border: 1px solid $border-color;
        border-collapse: collapse;
        width: 100%;
        &__row {
          &__head {
            display: block;
            border-top: 1px solid $border-color;
            font-size: 1.5rem;
            color: $font-color-flipped;
            padding: 12px 8px;
            background-color: $accent-color;
            &--none {
              border-top: none;
            }
          }
          &__data {
            display: block;
            border-top: 1px solid $border-color;
            font-size: 1.4rem;
            color: $font-color;
            padding: 12px 8px;
            &__note {
              margin-top: 8px;
              font-size: 1.2rem;
              color: $font-color-weak;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $min-width-threshold) {

.view {
  &__breadcrumbs {
    margin: 0 $content-space-wide;
  }
  &__content {
    margin: 32px calc($content-space-wide + 8px) 0 calc($content-space-wide + 8px);
    &__title {
      font-size: 3.6rem;
    }
    &__body {
      padding: 0 8px;
      &__table {
        &__row {
          &__head {
            display: table-cell;
            border: 1px solid $border-color;
            border-collapse: collapse;
            width: 22.2%;
            min-width: 224px;
          }
          &__data {
            display: table-cell;
            border: 1px solid $border-color;
            border-collapse: collapse;
          }
        }
      }
    }
  }
}

}
</style>
