<template>
  <ul class="breadcrumbs">
    <li v-for="(l, index) in links" :key="l.title" class="breadcrumbs__item">
      <MyLink v-if="!lastLink(index)" class="breadcrumbs__item__link" v-bind="l" />
      <span v-else class="breadcrumbs__item__text">{{ l.title }}</span>
    </li>
  </ul>
  <component :is="'script'" v-html="breadcrumbListJsonLd" type="application/ld+json"></component>
</template>

<script>
import MyLink from '../Atoms/MyLink.vue'

export default {
  name: 'MyBreadcrumbs',
  components: { MyLink },
  props: {
    links: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    lastLink(index) {
      return index === (this.links.length - 1)
    }
  },
  computed: {
    breadcrumbListJsonLd() {
      const data = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': this.links.map((d, index) => {
          const elem = {
            '@type': 'ListItem',
            'position': index + 1,
            'name': d.title,
          }
          if (!this.lastLink(index)) {
            elem['item'] = 'https://' + location.host + d.to
          }
        })
      }
      return JSON.stringify(data)
    }
  }
}
</script>

<style lang="scss" scoped>
$item-font-size: 1.2rem;

.breadcrumbs {
  &__item {
    display: inline-block;
    position: relative;
    &__link {
      font-size: $item-font-size;
    }
    &__text {
      font-size: $item-font-size;
    }
    &:not(:first-child) {
      margin-left: 20px;
    }
    &:not(:last-child):after {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      top: 48%;
      right: -10px;
      border-bottom: 1px solid $border-color;
      border-right: 1px solid $border-color;
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}

@media screen and (min-width: $min-width-threshold) {

$item-font-size-wide: 1.3rem;

.breadcrumbs {
  &__item {
    &__link {
      font-size: $item-font-size-wide;
    }
    &__text {
      font-size: $item-font-size-wide;
    }
    &:not(:last-child):after {
      width: 7px;
      height: 7px;
    }
  }
}

}
</style>
