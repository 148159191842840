<template>
  <button class="button" :class="buttonClass" @click="onClick">{{ title }}</button>
</template>

<script>
import { withModifier } from '../../utils/style'

export default {
  name: 'ToggleButton',
  props: {
    title: String,
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick(e) {
      this.$emit('onClick', e)
    }
  },
  computed: {
    buttonClass() {
      return this.selected ?
        [withModifier('button', 'selected')] :
        [withModifier('button', 'unselected')];
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  padding: 8px 12px;
  font-size: 1.6rem;
  border: 1px solid $main-color;
  &--selected {
    background-color: $main-color;
    color: $font-color-flipped;
    font-weight: bold;
  }
  &--unselected {
    color: $font-color;
    &:hover {
      background-color: $main-color-light;
    }
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
