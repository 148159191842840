<template>
  <ul class="list">
    <li class="list__item" :class="itemClass(d)" v-for="d in data" :key="d.id">
      <router-link v-if="!d.disabled" class="list__item__router" :to="d.link">
        <div class="list__item__router__thumbnail list__item__router__thumbnail--active">
          <RoundThumbnail v-bind="d" />
        </div>
        <a class="list__item__router__link list__item__router__link--active">{{ d.title }}</a>
      </router-link>
      <div v-else class="list__item__router">
        <div class="list__item__router__thumbnail">
          <RoundThumbnail v-bind="d" :effect="roundThumbnailEffectGrayscale" />
        </div>
        <a class="list__item__router__link list__item__router__link--disabled">{{ d.title }}</a>
      </div>
    </li>
  </ul>
</template>

<script>
import RoundThumbnail, { ConstantsMixin as RoundThumbnailConstantsMixin } from '../Atoms/RoundThumbnail.vue'

export default {
  mixins: [RoundThumbnailConstantsMixin],
  name: 'IconList',
  components: { RoundThumbnail },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    itemClass(datum) {
      return datum.disabled ? 'list__item--desabled' : 'list__item--active'
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  &__item {
    &--active {
      cursor: pointer;
    }
    &__router {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__link {
        margin-top: 8px;
        font-size: 1.6rem;
        &--active {
          color: $sub-color;
        }
        &--desabled {
          color: $font-color;
        }
      }
    }
  }
}

.list__item:hover {
  .list__item__router__link--active {
    color: $sub-color-light;
  }
}
.list__item:hover {
  .list__item__router__thumbnail--active {
    opacity: 0.85;
  }
}

@media screen and (min-width: $min-width-threshold) {

.list {
  row-gap: 24px;
  column-gap: 48px;
  &__item {
    &__router {
      &__link {
        font-size: 1.8rem;
      }
    }
  }
}

}
</style>
