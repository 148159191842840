<template>
  <div class="view">
    <IndicatorCover v-if="isLoading" />
    <div v-if="dataLoaded">
      <div class="view__breadcrumbs">
        <MyBreadcrumbs :links="breadcrumbs" />
      </div>
      <div class="view__content">
        <h1 class="view__content__title">{{ title }}</h1>
        <MyNote class="view__content__note" :type="myNoteTypeKome" :data="notes" />
        <div class="view__content__body">
          <div class="view__content__body__form">
            <div id="formProductId" class="view__content__body__form__item--middle">
              <ItemForm
                :groups="data.groups" :selected="form.productId" :errors="error.productId"
                @on-change-product="onChangeProduct" />
            </div>
            <div id="formName" class="view__content__body__form__item view__content__body__form__item--middle">
              <NameForm
                :value="form.name" :errors="error.name"
                @on-input-name="onInputName" @on-change-name="onChangeName" />
            </div>
            <div id="formAddress" class="view__content__body__form__item">
              <AddressForm
                :zip1="form.zip1" :zip2="form.zip2" :prefecture="form.prefecture" :address="form.address" :building="form.building"
                :zip-errors="error.zip" :prefecture-errors="error.prefecture" :address-errors="error.address" :building-errors="error.building"
                @on-input-zip1="onInputZip1" @on-input-zip2="onInputZip2" @on-change-zip2="onChangeZip"
                @on-change-prefecture="onChangePrefecture"
                @on-input-address="onInputAddress" @on-change-address="onChangeAddress"
                @on-input-building="onInputBuilding" @on-change-building="onChangeBuilding" />
            </div>
            <div id="formDeliveryType" class="view__content__body__form__item">
              <DeliveryTypeForm
                :value="form.deliveryType" :errors="error.deliveryType" :around-yuzawa="aroundYuzawa"
                @on-change-delivery-type="onChangeDeliveryType" />
            </div>
            <div v-if="isDelivery" id="formDeliveryName" class="view__content__body__form__item">
              <DeliveryNameForm
                :value="form.deliveryName" :errors="error.deliveryName"
                @on-input-delivery-name="onInputDeliveryName" @on-change-delivery-name="onChangeDeliveryName" />
            </div>
            <div id="formPaymentType" class="view__content__body__form__item">
              <PaymentTypeForm
                :value="form.paymentType" :errors="error.paymentType" :is-direct-delivery="isDirectDelivery"
                @on-change-payment-type="onChangePaymentType" />
            </div>
            <div id="formDeliveryDay" class="view__content__body__form__item">
              <DeliveryDayForm :values="form.deliveryDays" @on-change-delivery-day="onChangeDeliveryDay" />
            </div>
            <div id="formDeliveryTime" class="view__content__body__form__item" >
              <DeliveryTimeForm
                :value="form.deliveryTime" :errors="error.deliveryTime" :is-delivery="isDelivery"
                @on-change-delivery-time="onChangeDeliveryTime" />
            </div>
            <div v-if="isDirectDelivery" id="formTel" class="view__content__body__form__item view__content__body__form__item--middle">
              <TelForm
                :value="form.tel" :errors="error.tel"
                @on-input-tel="onInputTel" @on-change-tel="onChangeTel" />
            </div>
            <div id="formEmail" class="view__content__body__form__item view__content__body__form__item--middle">
              <EmailForm
                :value="form.email" :errors="error.email"
                @on-input-email="onInputEmail" @on-change-email="onChangeEmail" />
            </div>
            <div id="formNote" class="view__content__body__form__item">
              <NoteForm
                :value="form.note" :errors="error.note"
                @on-input-note="onInputNote" @on-change-note="onChangeNote" />
            </div>
          </div>
          <div class="view__content__body__detail">
            <OrderDetail :data="orders" />
          </div>
          <div class="view__content__body__buttons">
            <MyButton class="view__content__body__buttons__button"
              title="確認する" :size="myButtonSizeMiddle"
              @on-click="onClickConfirm" />
            <MyButton class="view__content__body__buttons__button"
              title="もどる" :type="myButtonTypeSecondary" :size="myButtonSizeMiddle"
              @on-click="onClickBack" />
          </div>
        </div>
      </div>
      <transition name="fade">
        <div v-if="openingSubview" class="subview">
          <div class="subview__content">
            <ConfirmView :orders="orders" :data="form"
              @on-click-submit="onClickSubmit" @on-click-cancel="onClickCancel" />
          </div>
          <div class="subview__background" @click="hide"></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../firebase'
import Plan from '../../models/Plan'
import { isEmpty, setupMetaTags } from '../../utils/helper.js'
import { aroundYuzawa, isDirectDelivery, isDelivery, isDirectPayment } from '../../utils/constants.js'
import { NotFound } from '../../utils/errors.js'
import MyButton, { ConstantsMixin as MyButtonConstantsMixin } from '../Atoms/MyButton.vue'
import MyBreadcrumbs from '../Presentations/MyBreadcrumbs.vue'
import ItemForm from '../Presentations/ItemForm.vue'
import IndicatorCover from '../Presentations/IndicatorCover.vue'
import NameForm from '../Presentations/NameForm.vue'
import AddressForm from '../Presentations/AddressForm.vue'
import PaymentTypeForm from '../Presentations/PaymentTypeForm.vue'
import DeliveryTypeForm from '../Presentations/DeliveryTypeForm.vue'
import DeliveryTimeForm from '../Presentations/DeliveryTimeForm.vue'
import DeliveryDayForm from '../Presentations/DeliveryDayForm.vue'
import DeliveryNameForm from '../Presentations/DeliveryNameForm.vue'
import TelForm from '../Presentations/TelForm.vue'
import EmailForm from '../Presentations/EmailForm.vue'
import NoteForm from '../Presentations/NoteForm.vue'
import OrderDetail from '../Presentations/OrderDetail.vue'
import MyNote, { ConstantsMixin as MyNoteConstantsMixin } from '../Atoms/MyNote.vue'
import ConfirmView from './ConfirmView.vue'
import {
  validateYear,
  validateId,
  validateProductId,
  validateName,
  validateZip,
  validatePrefecture,
  validateAddress,
  validateBuilding,
  validatePaymentType,
  validateDeliveryType,
  validateTel,
  validateEmail,
  validateDeliveryDays,
  validateDeliveryTime,
  validateNote,
  validateDeliveryName
} from '../common/validation.js'

/**
 * データの取得
 * @param {string} year
 * @param {string} planId
 * @returns {Promise<Object>}
 */
async function fetchData(year, planId) {
  const plan = await Plan.context({ year }).getDoc(planId)
  if (!plan || plan.isDisabled || plan.noSale || plan.beforeSale || plan.afterSale) {
    throw new NotFound()
  }
  const groups = await filterGroups(await plan.getGroups([orderBy('sortNum')]))
  if (groups.length === 0) {
    throw new NotFound()
  }
  return { plan, groups }
}

/**
 * 商品グループをフィルターする
 * @param {Array<Group>} groups
 * @returns {Promise<Array<Group>>}
 */
async function filterGroups(groups) {
  const retGroups = []
  for (const group of groups) {
    if (group.disable || group.beforeSale || group.afterSale) {
      continue
    }
    const products = await filterProducts(await group.getProducts([orderBy('sortNum')]))
    if (products.length === 0) {
      continue
    }
    group.products = products
    retGroups.push(group)
  }
  if (retGroups.length === 0) {
    throw new NotFound()
  }
  return retGroups
}

/**
 * 商品をフィルターする
 * @param {Array<Product>} products
 * @returns {Promise<Array<Product>>}
 */
async function filterProducts(products) {
  const retProducts = []
  for (const product of products) {
    if (!await product.canBuy()) {
      continue
    }
    retProducts.push(product)
  }
  return retProducts
}

/**
 * フォーム情報の作成
 * @param {Object} data
 * @returns {Object}
 */
function makeForm(data) {
  let zip = ''
  let zip1 = ''
  let zip2 = ''
  let prefecture = ''
  let address = ''
  let building = ''
  let name = ''
  let tel = ''
  let email = ''
  const currentAddress = window.localStorage.getItem('currentAddress')
  if (currentAddress) {
    [ zip, prefecture, address, building, name, email, tel ] = currentAddress.split('\n')
    zip1 = zip.substring(0, 3)
    zip2 = zip.substring(3)
  }
  return {
    year: data.plan.year,
    planId: data.plan.id,
    groupId: null,
    productId: null,
    name,
    zip1,
    zip2,
    prefecture,
    address,
    building,
    paymentType: null,
    deliveryType: null,
    deliveryName: '',
    deliveryTime: 1, // 指定なし
    tel: tel || '',
    email,
    deliveryDays: [1, 2, 3, 4, 5, 6, 0],
    note: ''
  }
}

/**
 * ラベルに含まれているか
 * @param {array} labels
 * @param {string} name
 * @returns {bool}
 */
function includesLabels(labels, name) {
  return labels.includes(name) || labels.includes('*')
}

export default {
  mixins: [MyButtonConstantsMixin, MyNoteConstantsMixin],
  name: 'OrderView',
  components: {
    MyButton,
    MyBreadcrumbs,
    ItemForm,
    IndicatorCover,
    NameForm,
    AddressForm,
    DeliveryTypeForm,
    DeliveryTimeForm,
    DeliveryDayForm,
    DeliveryNameForm,
    TelForm,
    EmailForm,
    NoteForm,
    PaymentTypeForm,
    OrderDetail,
    MyNote,
    ConfirmView
  },
  props: {
    planId: String
  },
  data() {
    return {
      isLoading: true,
      data: null,
      form: null,
      error: {},
      dataLoaded: false,
      buttonEnable: false,
      // 確認Viewが
      openingSubview: false
    }
  },
  methods: {
    /**
     * 対象のグループと商品を検索する
     * @param {string} productId
     * @returns {Object}
     */
    searchGroupAndProduct(productId) {
      for (const group of this.data.groups) {
        const product = group.products.find(p => p.id === productId)
        if (product) {
          return { group, product }
        }
      }
    },
    onChangeProduct(productId) {
      const { group, product } = this.searchGroupAndProduct(productId)
      this.form = { ...this.form, groupId: group.id, productId: product.id }
    },
    onInputName(value) {
      // ローカルストレージに保存された住所情報は改行をデリミタとしているので改行は削除
      value = value.replaceAll('\n', '')
      this.form = { ...this.form, name: value }
    },
    onChangeName() {
      this.validate(['name'])
    },
    onInputZip1(value) {
      const form = { ...this.form, zip1: value }
      // 湯沢以外の地域で、直接引き渡しを選択していた場合は未選択にする
      if (!aroundYuzawa(value, this.form.zip2) && isDirectDelivery(this.form.deliveryType)) {
        form.deliveryType = null
        // さらに現金支払いを選択していた場合は未選択にする
        if (isDirectPayment(this.form.paymentType)) {
          form.paymentType = null
        }
      }
      this.form = form
    },
    onInputZip2(value) {
      const form = { ...this.form, zip2: value }
      if (!aroundYuzawa(this.form.zip1, value) && isDirectDelivery(this.form.deliveryType)) {
        form.deliveryType = null
        if (isDirectPayment(this.form.paymentType)) {
          form.paymentType = null
        }
      }
      this.form = form
    },
    onChangeZip() {
      this.validate(['zip'])
    },
    onChangePrefecture(value) {
      this.form = { ...this.form, prefecture: value }
      this.validate(['prefecture'])
    },
    onInputAddress(value) {
      // ローカルストレージに保存された住所情報は改行をデリミタとしているので改行は削除
      value = value.replaceAll('\n', '')
      this.form = { ...this.form, address: value }
    },
    onChangeAddress() {
      this.validate(['address'])
    },
    onInputBuilding(value) {
      // ローカルストレージに保存された住所情報は改行をデリミタとしているので改行は削除
      value = value.replaceAll('\n', '')
      this.form = { ...this.form, building: value }
    },
    onChangeBuilding() {
      this.validate(['building'])
    },
    onChangePaymentType(value) {
      this.form = { ...this.form, paymentType: value }
      this.validate([])
    },
    onChangeDeliveryType(value) {
      const form = { ...this.form, deliveryType: value }
      // 直接引き渡し以外を選択し、現金支払いを選択していた場合は未選択にする
      if (!isDirectDelivery(value) && isDirectPayment(this.form.paymentType)) {
        form.paymentType = null
      }
      // 宅配便以外の場合はお届け先氏名はリセットする
      if (!isDelivery(value)) {
        form.deliveryName = ''
      }
      this.form = form
      this.validate([])
    },
    onChangeDeliveryTime(value) {
      this.form = { ...this.form, deliveryTime: value }
      this.validate([])
    },
    onInputDeliveryName(value) {
      this.form = { ...this.form, deliveryName: value }
    },
    onChangeDeliveryName() {
      this.validate(['deliveryName'])
    },
    onInputTel(value) {
      this.form = { ...this.form, tel: value }
    },
    onChangeTel() {
      this.validate(['tel'])
    },
    onInputEmail(value) {
      this.form = { ...this.form, email: value }
    },
    onChangeEmail() {
      this.validate(['email'])
    },
    onChangeDeliveryDay(value) {
      const deliveryDays = this.form.deliveryDays.includes(value) ?
        this.form.deliveryDays.filter(v => v !== value) : this.form.deliveryDays.concat(value)
      this.form = { ...this.form, deliveryDays: deliveryDays }
      this.validate([])
    },
    onInputNote(value) {
      this.form = { ...this.form, note: value }
    },
    onChangeNote() {
      this.validate(['note'])
    },
    /**
     * 全体バリデーション
     * @param {array} errLabales エラー出力するラベル、*で全ラベル対象
     * @returns {bool} 成否
     */
    validate(errLabales = ['*']) {
      let isValid = true
      // 特定のエラーメッセージを表示しない値
      try {
        // 年
        validateYear(this.form.year)
        // 商品グループID
        validateId(this.form.groupId)
        // お届け希望曜日
        validateDeliveryDays(this.form.deliveryDays)
        // お届け希望時間
        validateDeliveryTime(this.form.deliveryTime, this.form.deliveryType)
      } catch (e) {
        isValid = false
      }
      // 商品ID
      try {
        validateProductId(this.form.productId)
        this.error = { ...this.error, productId: [] }
      } catch (e) {
        isValid = false
        if (includesLabels(errLabales, 'productId')) {
          this.error = { ...this.error, productId: e.messages }
        }
      }
      // ご注文者名
      try {
        validateName(this.form.name)
        this.error = { ...this.error, name: [] }
      } catch (e) {
        isValid = false
        if (includesLabels(errLabales, 'name')) {
          this.error = { ...this.error, name: e.messages }
        }
      }
      // 郵便番号
      try {
        validateZip(this.form.zip1 + this.form.zip2)
        this.error = { ...this.error, zip: [] }
      } catch (e) {
        isValid = false
        if (includesLabels(errLabales, 'zip')) {
          this.error = { ...this.error, zip: e.messages }
        }
      }
      // 都道府県
      try {
        validatePrefecture(this.form.prefecture)
        this.error = { ...this.error, prefecture: [] }
      } catch (e) {
        isValid = false
        if (includesLabels(errLabales, 'prefecture')) {
          this.error = { ...this.error, prefecture: e.messages }
        }
      }
      // 住所
      try {
        validateAddress(this.form.address)
        this.error = { ...this.error, address: [] }
      } catch (e) {
        isValid = false
        if (includesLabels(errLabales, 'address')) {
          this.error = { ...this.error, address: e.messages }
        }
      }
      // 建物
      try {
        validateBuilding(this.form.building)
        this.error = { ...this.error, building: [] }
      } catch (e) {
        isValid = false
        if (includesLabels(errLabales, 'building')) {
          this.error = { ...this.error, building: e.messages }
        }
      }
      // 配送方法
      try {
        validateDeliveryType(this.form.deliveryType, this.form.zip1, this.form.zip2)
        this.error = { ...this.error, deliveryType: [] }
      } catch (e) {
        isValid = false
        if (includesLabels(errLabales, 'deliveryType')) {
          this.error = { ...this.error, deliveryType: e.messages }
        }
      }
      // お届け先氏名
      try {
        if (isDelivery(this.form.deliveryType)) {
          validateDeliveryName(this.form.deliveryName)
        }
        this.error = { ...this.error, deliveryName: [] }
      } catch (e) {
        isValid = false
        if (includesLabels(errLabales, 'deliveryName')) {
          this.error = { ...this.error, deliveryName: e.messages }
        }
      }
      // 支払方法
      try {
        validatePaymentType(this.form.paymentType, this.form.deliveryType)
        this.error = { ...this.error, paymentType: [] }
      } catch (e) {
        isValid = false
        if (includesLabels(errLabales, 'paymentType')) {
          this.error = { ...this.error, paymentType: e.messages }
        }
      }
      // お電話番号
      try {
        if (isDirectDelivery(this.form.deliveryType)) {
          validateTel(this.form.tel)
        }
        this.error = { ...this.error, tel: [] }
      } catch (e) {
        isValid = false
        if (includesLabels(errLabales, 'tel')) {
          this.error = { ...this.error, tel: e.messages }
        }
      }
      // メールアドレス
      try {
        validateEmail(this.form.email)
        this.error = { ...this.error, email: [] }
      } catch (e) {
        isValid = false
        if (includesLabels(errLabales, 'email')) {
          this.error = { ...this.error, email: e.messages }
        }
      }
      // ご要望等
      try {
        validateNote(this.form.note)
        this.error = { ...this.error, note: [] }
      } catch (e) {
        isValid = false
        if (includesLabels(errLabales, 'note')) {
          this.error = { ...this.error, note: e.messages }
        }
      }

      return isValid
    },
    /**
     * エラー位置までスクロールする
     */
    scrollToErrorBlock() {
      if (!isEmpty(this.error.productId)) {
        this.$scrollTo(document.querySelector('#formProductId'), 500, { offset: -60 })
        return
      }
      if (!isEmpty(this.error.name)) {
        this.$scrollTo(document.querySelector('#formName'), 500, { offset: -60 })
        return
      }
      if (!isEmpty(this.error.zip) ||
          !isEmpty(this.error.prefecture) ||
          !isEmpty(this.error.address) ||
          !isEmpty(this.error.building)) {
        this.$scrollTo(document.querySelector('#formAddress'), 500, { offset: -60 })
        return
      }
      if (!isEmpty(this.error.deliveryType)) {
        this.$scrollTo(document.querySelector('#formDeliveryType'), 500, { offset: -60 })
        return
      }
      if (!isEmpty(this.error.deliveryName)) {
        this.$scrollTo(document.querySelector('#formDeliveryName'), 500, { offset: -60 })
        return
      }
      if (!isEmpty(this.error.paymentType)) {
        this.$scrollTo(document.querySelector('#formPaymentType'), 500, { offset: -60 })
        return
      }
      if (!isEmpty(this.error.tel)) {
        this.$scrollTo(document.querySelector('#formTel'), 500, { offset: -60 })
        return
      }
      if (!isEmpty(this.error.email)) {
        this.$scrollTo(document.querySelector('#formEmail'), 500, { offset: -60 })
        return
      }
      if (!isEmpty(this.error.note)) {
        this.$scrollTo(document.querySelector('#formNote'), 500, { offset: -60 })
        return
      }
    },
    getNewAddress() {
      // TODO: 個人情報のローカル保存についてはセキュリティを考慮した上で実装する
      return ''
      //return this.form.zip1 + this.form.zip2 + '\n' +
      //       this.form.prefecture + '\n' +
      //       this.form.address + '\n' +
      //       this.form.building + '\n' +
      //       this.form.name + '\n' +
      //       this.form.email + '\n' +
      //       this.form.tel
    },
    onClickConfirm() {
      if (!this.validate()) {
        this.scrollToErrorBlock()
        return
      }
      this.openingSubview = true
    },
    onClickBack() {
      this.$router.push('/plan/' + this.planId)
    },
    async onClickSubmit() {
      this.openingSubview = false
      this.isLoading = true

      const addOrder = httpsCallable(functions, 'addOrder')
      try {
        const result = await addOrder(this.form)
        const data = result.data

        window.localStorage.setItem(data.orderId, data.secret)

        this.$router.push({ name: 'Complete', params: { orderId: data.orderId, newAddress: this.getNewAddress() }})
      } catch (e) {
        console.error(e)
        switch (e.code) {
          case 'functions/invalid-argument':
            this.error = e.details
            this.scrollToErrorBlock()
            break
          case 'functions/failed-precondition':
            this.$emit('onAlert', e.message)
            break
          default:
            this.$emit('onAlert', '通信に失敗しました。\n時間をおいてから再度お試しください。')
            break
        }
      } finally {
        this.isLoading = false
      }
    },
    onClickCancel() {
      this.openingSubview = false
    }
  },
  computed: {
    breadcrumbs() {
      return [
        { title: 'ホーム', to: '/' },
        { title: this.data.plan.name, to: this.data.plan.url },
        { title: '注文フォーム' }
      ]
    },
    title() {
      return this.data ? '注文フォーム【' + this.data.plan.name + '】' : ''
    },
    notes() {
      return [
        '注文内容はブラウザごとに保存されます。不特定多人数が使用するPC、スマホでのご注文にはご注意ください。'
      ]
    },
    orders() {
      const orders = []
      if (!this.form.productId) {
        return orders
      }
      const { group, product } = this.searchGroupAndProduct(this.form.productId)
      orders.push({
        item: group.name + ' ' + product.name,
        price: product.price
      })
      // 宅配便の場合は配送料金を追加
      if (this.form.deliveryType === 1) {
        orders.push({
          item: '配送料金',
          price: this.data.plan.deliveryPrice
        })
      }
      return orders
    },
    aroundYuzawa() {
      return aroundYuzawa(this.form.zip1, this.form.zip2)
    },
    isDirectDelivery() {
      return isDirectDelivery(this.form.deliveryType)
    },
    isDelivery() {
      return isDelivery(this.form.deliveryType)
    }
  },
  created() {
    fetchData((new Date()).getFullYear(), this.planId)
      .then(data => {
        setupMetaTags('注文フォーム【' + data.plan.name + '】 | やまのた直売所', data.plan.displayedDescription)
        this.data = data
        this.form = makeForm(data)
        this.dataLoaded = true
        this.isLoading = false
      })
      .catch(e => {
        console.error(e)
        this.$router.replace('/404')
      })
  }
}
</script>

<style lang="scss" scoped>
.view {
  &__breadcrumbs {
    margin: 0 $content-space;
  }
  &__content {
    margin: 8px calc($content-space + 4px) 0 calc($content-space + 4px);
    &__title {
      font-size: 2.6rem;
      color: $main-color;
    }
    &__note {
      margin-top: 8px;
    }
    &__body {
      margin-top: 24px;
      &__form {
        &__item {
          margin-top: 32px;
        }
      }
      &__detail {
        margin-top: 64px;
        padding: 0 4px;
      }
      &__buttons {
        margin-top: 64px;
        padding: 0 8px;
        &__button {
          display: block;
          width: 100%;
          &:not(:first-child) {
            margin-top: 16px;
          }
        }
      }
    }
  }
}

.subview {
  position: fixed;
  top: $header-height;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &__content {
    background-color: $base-color;
    height: 88vh;
    min-width: 94vw;
    margin: 0 $content-space;
    padding: 24px 16px;
    overflow-y: scroll;
    z-index: $subview-z-index;
  }
  &__background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: calc(#{$subview-z-index} - 1);
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.fade {
  &-enter-active, &-leave-active {
    transition: opacity 0.75s ease;
  }
  &-enter-from, &-leave-to {
    opacity: 0;
  }
}

@media screen and (min-width: $min-width-threshold) {

.view {
  &__breadcrumbs {
    margin: 0 $content-space-wide;
  }
  &__content {
    margin: 32px calc($content-space-wide + 8px) 0 calc($content-space-wide + 8px);
    &__title {
      font-size: 3.6rem;
    }
    &__note {
      margin-top: 24px;
    }
    &__body {
      margin: 24px 12px 0 12px;
      &__form {
        width: 50%;
        min-width: 343px;
        max-width: 715px;
        &__item--middle {
          max-width: 476px;
        }
      }
      &__detail {
        background-color: $base-color;
        position: fixed;
        top: 228px;
        right: calc($content-space-wide + 8px + 12px);
        width: 343px;
        margin-top: 0;
        padding: 0;
      }
      &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        &__button {
          display: initial;
          width: 240px;
          &:not(:first-child) {
            margin-top: 0;
            margin-left: 16px;
          }
        }
      }
    }
  }
}

.subview {
  &__content {
    margin: 0 $content-space-wide;
    padding: 32px 78px;
  }
}

}
</style>
