<template>
  <button class="icon" @click="onClick">
    <img class="icon__img" src="../../assets/twitter.svg" alt="Twitterシェア">
  </button>
</template>

<script>
/**
 * TwitterシェアURLの作成
 * @param {string} text
 * @param {string} url
 * @returns {string}
 */
function makeUrl(text, url) {
  const shareUrl = 'https://twitter.com/intent/tweet'
  const queryString = new URLSearchParams([['text', text], ['url', url]]).toString()
  return shareUrl + '?' + queryString
}

export default {
  name: 'TwitterIcon',
  methods: {
    onClick() {
      const title = document.querySelector('meta[name="twitter:title"]').content
      const description = document.querySelector('meta[name="twitter:description"]').content
      const url = location.href
      window.open(makeUrl(title + '\n' + description, url))
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  &__img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
