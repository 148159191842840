<template>
  <section class="feature">
    <img class="feature__img" :src="imageUrl" :alt="title">
    <img class="feature__imgwide" :src="imageWideUrl" :alt="title">
    <div class="feature__title">
      <h1 class="feature__title__text">{{ title }}</h1>
    </div>
    <div v-if="description" class="feature__description">
      <p class="feature__description__text">{{ description }}</p>
    </div>
    <div v-if="!noSale" class="feature__button">
      <div class="feature__button__inner">
        <MyButton class="feature__button__inner__text" :class="buttonClass" :title="buttonTitle" :type="buttonType" @on-click="onClick" />
      </div>
    </div>
  </section>
</template>

<script>
import { resrcName } from '../../utils/helper'
import { withModifier } from '../../utils/style'
import MyButton, { Constants as MyButtonConstants, ConstantsMixin as MyButtonConstantsMixin } from '../Atoms/MyButton.vue'

export default {
  mixins: [MyButtonConstantsMixin],
  name: 'MyFeature',
  components: { MyButton },
  props: {
    title: String,
    imageUrl: String,
    imageWideUrl: String,
    description: String,
    saleStartDate: Date,
    soldOut: {
      type: Boolean,
      default: false
    },
    beforeSale: {
      type: Boolean,
      default: false
    },
    afterSale: {
      type: Boolean,
      default: false
    },
    noSale: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    imageName(uri) {
      return resrcName(uri)
    },
    onClick(e) {
      this.$emit('onClick', e)
    }
  },
  computed: {
    buttonTitle() {
      if (this.beforeSale) {
        return (this.saleStartDate.getMonth() + 1) + '月' + this.saleStartDate.getDate() + '日' + '注文開始'
      }
      if (this.afterSale || this.soldOut) {
        return '販売終了しました'
      }
      return '注文する'
    },
    buttonType() {
      return this.soldOut ? MyButtonConstants.Type.Disabled : MyButtonConstants.Type.Primary
    },
    buttonClass() {
      return this.soldOut ? withModifier('feature__button__inner__text', 'disabled') : null
    }
  }
}
</script>

<style lang="scss" scoped>
.feature {
  position: relative;
  &__img {
    display: initial;
    width: 100%;
    height: $main-image-size;
    object-fit: cover;
  }
  &__imgwide {
    display: none;
    width: 100%;
    height: $main-image-size-wide;
    object-fit: cover;
  }
  &__title {
    position: absolute;
    top: 0;
    left: 0;
    &__text {
      margin: 40px $content-space 0 $content-space;
      font-size: 6.4rem;
      color: $font-color-flipped;
      text-shadow: 0px 0px 4px $font-color;
      opacity: 83.3%;
      text-align: start;
    }
  }
  &__description {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    &__text {
      margin: 276px calc($content-space-wide + 40px) 0 calc($content-space-wide + 40px);
      font-size: 2.4rem;
      color: $font-color-flipped;
      text-shadow: 0px 0px 4px $font-color;
      opacity: 83.3%;
      line-height: 3.2rem;
      text-align: start;
      white-space: pre-wrap;
    }
  }
  &__button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 52px;
      &__text {
        min-width: 89%;
        &--disabled {
          opacity: 0.89;
        }
      }
    }
  }
}

@media screen and (min-width: $min-width-threshold) {

.feature {
  &__img {
    display: none;
  }
  &__imgwide {
    display: initial;
  }
  &__title {
    &__text {
      margin: 52px calc($content-space-wide + 40px) 0 calc($content-space-wide + 40px);
      font-size: 11.2rem;
    }
  }
  &__description {
    display: initial;
    &__text {
      font-size: 3.8rem;
      line-height: 4.8rem;
    }
  }
  &__button {
    &__inner {
      align-items: start;
      margin-left: calc($content-space-wide + 40px);
      &__text {
        min-width: initial;
        width: 380px;
      }
    }
  }
}

}
</style>
