<template>
  <FormSection class="form" title="お届け希望曜日">
    <div class="form__block">
      <MyCheck class="form__block__check" name="deliveryDay" value="1" label="月曜" :checked="isChecked(1)" @on-change="onChage" />
      <MyCheck class="form__block__check" name="deliveryDay" value="2" label="火曜" :checked="isChecked(2)" @on-change="onChage" />
      <MyCheck class="form__block__check" name="deliveryDay" value="3" label="水曜" :checked="isChecked(3)" @on-change="onChage" />
      <MyCheck class="form__block__check" name="deliveryDay" value="4" label="木曜" :checked="isChecked(4)" @on-change="onChage" />
      <MyCheck class="form__block__check" name="deliveryDay" value="5" label="金曜" :checked="isChecked(5)" @on-change="onChage" />
      <MyCheck class="form__block__check" name="deliveryDay" value="6" label="土曜" :checked="isChecked(6)" @on-change="onChage" />
      <MyCheck class="form__block__check" name="deliveryDay" value="0" label="日曜" :checked="isChecked(0)" @on-change="onChage" />
    </div>
    <MyNote class="form__note" :type="myNoteTypeKome" :data="description" />
  </FormSection>
</template>

<script>
import FormSection from '../Atoms/FormSection.vue'
import MyCheck from '../Atoms/MyCheck.vue'
import MyNote, { ConstantsMixin as MyNoteConstantsMixin } from '../Atoms/MyNote.vue'

export default {
  mixins: [MyNoteConstantsMixin],
  name: 'DeliveryDayForm',
  components: { FormSection, MyCheck, MyNote },
  props: {
    values: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    isChecked(value) {
      return this.values.includes(value);
    },
    onChage(e) {
      this.$emit('onChangeDeliveryDay', Number(e.target.value))
    }
  },
  computed: {
    description() {
      return ['収穫状況によってはご希望に添えないことがあります。']
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  &__block {
    &__check {
      height: 38px;
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
  &__note {
    margin-top: 8px;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
