<template>
  <button class="button" :class="buttonClass" :style="style" :disabled="disabled" @click="onClick">{{ title }}</button>
</template>

<script>
import { withModifier } from '../../utils/style'

export const Constants = {
  Type: {
    Primary: 'primary',
    Secondary: 'secondary',
    Disabled: 'disabled'
  },
  Size: {
    Large: 'large',
    Middle: 'middle',
    Small: 'small'
  }
}

export const ConstantsMixin = {
  computed: {
    myButtonTypePrimary() {
      return Constants.Type.Primary
    },
    myButtonTypeSecondary() {
      return Constants.Type.Secondary
    },
    myButtonTypeDisabled() {
      return Constants.Type.Disabled
    },
    myButtonSizeLarge() {
      return Constants.Size.Large
    },
    myButtonSizeMiddle() {
      return Constants.Size.Middle
    },
    myButtonSizeSmall() {
      return Constants.Size.Small
    }
  }
}

export default {
  name: 'MyButton',
  props: {
    title: String,
    type: {
      type: String,
      default: Constants.Type.Primary,
      validator: v => Object.values(Constants.Type).includes(v)
    },
    size: {
      type: String,
      default: Constants.Size.Large,
      validator: v => Object.values(Constants.Size).includes(v)
    },
    style: Object
  },
  methods: {
    onClick(e) {
      if (this.type === Constants.Type.Disabled) {
        return
      }
      this.$emit('onClick', e)
    }
  },
  computed: {
    buttonClass() {
      return [
        withModifier('button', this.type),
        withModifier('button', this.size)
      ]
    },
    disabled() {
      return this.type === Constants.Type.Disabled
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  cursor: pointer;
  &--primary {
    background-color: $accent-color;
    color: $font-color-flipped;
    &:hover {
      background-color: $accent-color-light;
    }
    &:active {
      background-color: $accent-color-dark !important;
    }
  }
  &--secondary {
    background-color: $base-color;
    color: $accent-color;
    border: 1px solid $accent-color;
    &:hover {
      border-width: 2px;
      color: $accent-color-light;
      border-color: $accent-color-light;
    }
    &:active {
      border-width: 1px !important;
      background-color: $base-color-dark;
      color: $accent-color !important;
      border-color: $accent-color !important;
    }
  }
  &--disabled {
    cursor: default;
    background-color: $disabled-color;
    color: $font-color-flipped;
  }
  &--large {
    padding: 10px 24px;
    font-size: 3.2rem;
    border-radius: 32px / 50%;
  }
  &--middle {
    padding: 8px 16px;
    font-size: 2.4rem;
    border-radius: 24px / 50%;
  }
  &--small {
    padding: 8px 16px;
    font-size: 1.8rem;
    border-radius: 20px / 50%;
  }
}
.button--large.button--secondary {
  &:hover {
    padding: 9px 23px;
  }
  &:active {
    padding: 10px 24px !important;
  }
}
.button--middle.button--secondary {
  &:hover {
    padding: 7px 15px;
  }
  &:active {
    padding: 8px 16px !important;
  }
}
.button--small.button--secondary {
  &:hover {
    padding: 7px 15px;
  }
  &:active {
    padding: 8px 16px !important;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
