import Model from './Model'
import Charge from './Charge'

/**
 * 在庫
 */
export default class Stock extends Model {
  /**
   * パス
   * @type {string}
   */
  static path = '/year/:year/stocks';

  /**
   * 対象年
   * @type {string}
   */
  year
  /**
   * 量
   * @type {number}
   */
  amount

  /**
   * 残量の取得
   * @returns {Promise<number>}
   */
  async getRest() {
    const charges = await Charge.context(Object.assign(this.context, { stockId: this.id })).getDocs()
    const usageAmount = charges.reduce((prev, cur) => prev + cur.amount, 0)
    return this.amount - usageAmount
  }
}
