import Model from './Model'
import Usage from './Usage'

/**
 * 商品
 */
export default class Product extends Model {
  /**
   * パス
   * @type {string}
   */
  static path = '/year/:year/plans/:planId/groups/:groupId/products'

  /**
   * 商品名
   * @type {string}
   */
  name
  /**
   * 税込値段
   * @type {number}
   */
  price
  /**
   * ソート番号
   * @type {number}
   */
  sortNum
  /**
   * 作成日
   * @type {DateTime}
   */
  createdAt

  /**
   * 購入可能か
   * @returns {Promise<boolean>}
   */
  async canBuy() {
    const usages = await this.getUsages()
    for (const usage of usages) {
      const stock = await usage.getStock()
      // ストックが設定されていない場合は購入不可能
      if (!stock) {
        return false
      }
      if (usage.amount > await stock.getRest()) {
        return false
      }
    }
    return true
  }

  /**
   * 商品構成要素の取得
   * @param {Array<Query<T>>} queries
   * @returns {Promise<Array<Usage>>}
   */
  async getUsages(queries = []) {
    return await Usage.context(Object.assign({}, this.context, { productId: this.id })).getDocs(queries)
  }
}
