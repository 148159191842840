<template>
  <router-view v-slot="{ Component, route }">
    <AppHeader ref="header" />
    <div class="content">
      <component :is="Component" @on-alert="onAlert" />
    </div>
    <AppFooter :with-social="route.meta.withSocial" />
    <MyDialog ref="dialog" />
  </router-view>
</template>

<script>
import { setupMetaTags, setupMetaRobots } from './utils/helper'
import { clearCache } from './models/cache'
import AppHeader from './components/Presentations/AppHeader.vue'
import AppFooter from './components/Presentations/AppFooter.vue'
import MyDialog from './components/Presentations/MyDialog.vue'

export default {
  name: 'App',
  components: { MyDialog, AppHeader, AppFooter },
  methods: {
    onAlert(message) {
      this.$refs.dialog.show(message)
    }
  },
  created() {
    // ページ移動した際の処理を登録する
    this.$router.beforeEach((to) => {
      this.$refs.header.closeDrawer()
      // ページを変更した時にキャッシュをクリア
      clearCache()

      setupMetaRobots(to.meta.noindex, to.meta.nofollow)
      if (to.meta.hasMeta) {
        setupMetaTags(to.meta.title, to.meta.description)
      }
    })
  }
}
</script>

<style>
@import './styles/normalize.css';
@import './styles/carousel.css';
</style>

<style lang="scss" scoped>
/* scss変数の読み込みはビルド時に行っている。vue.config.jsを参照 */
.content {
  margin-top: calc($header-height + 16px);
}
</style>
