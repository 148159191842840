<template>
  <a v-if="external" class="link" :target="target" :rel="externalRel" :href="to" @click="onClick">{{ title }}</a>
  <router-link v-else class="link" :to="to">{{ title }}</router-link>
</template>

<script>
export default {
  name: 'MyLink',
  props: {
    title: String,
    to: String,
    external: {
      type: Boolean,
      default: false
    },
    target: String
  },
  methods: {
    onClick(e) {
      this.$emit('onClick', e)
    }
  },
  computed: {
    externalRel() {
      return this.target === '_blank' ? 'noopener noreferrer' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  color: $sub-color;
  cursor: pointer;
  &:hover {
    color: $sub-color-light;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
