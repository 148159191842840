import Prefectures from '../../../constants/prefectures.json'
import PaymentTypes from '../../../constants/paymentTypes.json'
import DeliveryTimes from '../../../constants/deliveryTimes.json'
import DeliveryTypes from '../../../constants/deliveryTypes.json'
import DeliveryDays from '../../../constants/deliveryDays.json'
import Validator from '../../utils/validator'
import { aroundYuzawa, isDirectDelivery, isDirectPayment, isDelivery } from '../../utils/constants.js'

/**
 * バリデーションエラー
 */
export class ValidationError extends Error {
  /**
   * @type {array}
   */
  messages

  /**
   * @param {array} messages
   */
  constructor(messages) {
    super('バリデーションエラー')

    this.messages = messages
  }
}

/**
 * ID系のバリデーション
 * @param {string} value
 * @throws {ValidationError}
 */
export function validateId(value) {
  const validator = new Validator('ID', value)
  validator
    .required({ isBroken: true })
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * 商品のバリデーション
 * @param {string} value
 * @throws {ValidationError}
 */
export function validateProductId(value) {
  const validator = new Validator('商品', value)
  validator
    .required({ isBroken: true })
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * 年のバリデーション
 * @param {string} value
 * @throws {ValidationError}
 */
export function validateYear(value) {
  const validator = new Validator('年', value)
  validator
    .required({ isBroken: true })
    .equals((new Date()).getFullYear())
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * ご注文者名のバリデーション
 * @param {string} value
 * @throws {ValidationError}
 */
export function validateName(value) {
  const validator = new Validator('ご注文者名', value)
  validator
    .required({ isBroken: true })
    .maxLength(100)
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * 郵便番号のバリデーション
 * @param {string} value
 * @throws {ValidationError}
 */
export function validateZip(value) {
  const validator = new Validator('郵便番号', value)
  validator
    .required({ isBroken: true })
    .length(7)
    .isDigit()
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * 都道府県のバリデーション
 * @param {string} value
 * @throws {ValidationError}
 */
export function validatePrefecture(value) {
  const validator = new Validator('都道府県', value)
  validator
    .required({ isBroken: true })
    .includes(Prefectures, { message: '存在しない都道府県です。県名は「都府県」まで含めて入力してください。' })
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * 住所のバリデーション
 * @param {string} value
 * @throws {ValidationError}
 */
export function validateAddress(value) {
  const validator = new Validator('住所', value)
  validator
    .required({ isBroken: true })
    .maxLength(100)
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * 建物名のバリデーション
 * @param {string} value
 * @throws {ValidationError}
 */
export function validateBuilding(value) {
  const validator = new Validator('建物名', value)
  validator
    .maxLength(100)
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * 配送方法のバリデーション
 * @param {number} value
 * @param {string} zip1
 * @param {string} zip2
 * @throws {ValidationError}
 */
export function validateDeliveryType(value, zip1, zip2) {
  const validator = new Validator('配送方法', value)
  validator
    .required({ isBroken: true })
    .isNumber({ isBroken: true })
    .includes(DeliveryTypes.map(d => d.value))
    .custom(
      // 湯沢市ならばどの配送方法でも可、それ以外の地域は直接引き渡しはできない
      value => aroundYuzawa(zip1, zip2) || !isDirectDelivery(value),
      '直接引き渡しは特定の地域のみに限ります。'
    )
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * 支払方法のバリデーション
 * @param {number} value
 * @param {number} deliveryType
 * @throws {ValidationError}
 */
export function validatePaymentType(value, deliveryType) {
  const validator = new Validator('支払方法', value)
  validator
    .required({ isBroken: true })
    .isNumber({ isBroken: true })
    .includes(PaymentTypes.map(d => d.value))
    .custom(
      // 直接引き渡しならばどの支払方法でも可、それ以外の方法は現金支払できない
      value => isDirectDelivery(deliveryType) || !isDirectPayment(value),
      '現金支払いは直接引き渡しのみに限ります。'
    )
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * お電話番号のバリデーション
 * @param {string} value
 * @throws {ValidationError}
 */
export function validateTel(value) {
  const validator = new Validator('お電話番号', value)
  validator
    .required({ isBroken: true })
    .isDigit()
    .maxLength(20)
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * メールアドレスのバリデーション
 * @param {string} value
 * @throws {ValidationError}
 */
export function validateEmail(value) {
  const validator = new Validator('メールアドレス', value)
  validator
    .required({ isBroken: true })
    .email()
    .maxLength(100)
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * お届け希望曜日のバリデーション
 * @param {array} value
 * @throws {ValidationError}
 */
export function validateDeliveryDays(value) {
  const validator = new Validator('お届け希望曜日', value)
  validator
    .required({ isBroken: true })
    .isArray({ isBroken: true })
    .memberIncludes(DeliveryDays.map(d => d.value))
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * お届け希望時間のバリデーション
 * @param {number} value
 * @param {number} deliveryType
 * @throws {ValidationError}
 */
export function validateDeliveryTime(value, deliveryType) {
  const validator = new Validator('お届け希望時間', value)
  validator
    .required({ isBroken: true })
    .isNumber({ isBroken: true })
    .includes(DeliveryTimes.filter(d => (d.onlyDelivery && isDelivery(deliveryType)) || !d.onlyDelivery).map(d => d.value))
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * ご要望等のバリデーション
 * @param {string} value
 * @throws {ValidationError}
 */
export function validateNote(value) {
  const validator = new Validator('ご要望等', value)
  validator
    .maxLength(1000)
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * 到着予定日のバリデーション
 * @param {string} value
 * @throws {ValidationError}
 */
export function validateDeliveryDate(value) {
  const validator = new Validator('到着予定日', value)
  validator
    .required({ isBroken: true })
    .isDateString()
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}

/**
 * お届け先氏名のバリデーション
 * @param {string} value
 * @throws {ValidationError}
 */
export function validateDeliveryName(value) {
  const validator = new Validator('お届け先氏名', value)
  validator
    .maxLength(100)
  if (!validator.isValid()) {
    throw new ValidationError(validator.messages)
  }
}
