<template>
  <div class="view">
    <div class="view__feature">
      <MyFeature
        :title="data.plan.name" :description="data.plan.displayedDescription" :sale-start-date="data.plan.startDate"
        :sold-out="data.soldOut" :before-sale="data.plan.beforeSale" :after-sale="data.plan.afterSale" :no-sale="data.plan.noSale"
        :imageUrl="data.plan.featureImageUrl" :imageWideUrl="data.plan.featureImageWideUrl"
        @on-click="onClickOrder" />
    </div>
    <div class="view__content">
      <ThemeSection class="view__content__detail" title="詳細">
        <PlanDescription class="view__content__detail__description"
          :image="data.plan.descriptionImageUrl" :description="data.plan.displayedDescription" />
        <div class="view__content__detail__items">
          <h3 class="view__content__detail__items__title">品目</h3>
          <ItemList class="view__content__detail__items__list" :items="data.items" />
        </div>
      </ThemeSection>
    </div>
    <div v-if="!data.plan.noSale" class="view__button">
      <MyButton class="view__button__text" :title="buttonTitle" :type="buttonType" @on-click="onClickOrder" />
    </div>
  </div>
  <component v-if="data.plan.inSale" :is="'script'" v-html="productJsonLd" type="application/ld+json"></component>
</template>

<script>
import Division from '../../../../constants/division.json'
import MyButton, { Constants as MyButtonConstants, ConstantsMixin as MyButtonConstantsMixin } from '../../Atoms/MyButton.vue'
import ThemeSection from '../../Atoms/ThemeSection.vue'
import MyFeature from '../../Presentations/MyFeature.vue'
import PlanDescription from '../../Presentations/PlanDescription.vue'
import ItemList from '../../Presentations/ItemList.vue'

export default {
  mixins: [MyButtonConstantsMixin],
  name: 'GeneralView',
  components: {
    MyButton,
    ThemeSection,
    MyFeature,
    PlanDescription,
    ItemList
  },
  props: {
    data: Object,
  },
  methods: {
    onClickOrder() {
      this.$router.push('/plan/' + this.data.plan.id + '/order')
    }
  },
  computed: {
    itemTitle() {
      return this.data.plan.division === Division.Set ? '品目' : '品種'
    },
    buttonTitle() {
      if (this.data.plan.beforeSale) {
        return (this.data.plan.startDate.getMonth() + 1) + '月' + this.data.plan.startDate.getDate() + '日' + '注文開始'
      }
      if (this.data.plan.afterSale || this.data.soldOut) {
        return '販売終了しました'
      }
      return '注文する'
    },
    buttonType() {
      return this.data.soldOut ? MyButtonConstants.Type.Disabled : MyButtonConstants.Type.Primary
    },
    productJsonLd() {
      const data = this.data.groups.filter(g => !!g.products && g.products.length > 0).map(g => {
        const prices = g.products.map(p => p.price)
        return {
          '@context': 'https://schema.org',
          '@type': 'Product',
          'name': g.name,
          'description': g.displayedDescription,
          'image': g.imageItemUrl,
          'url': 'https://' + location.host + '/plan/' + this.data.plan.id,
          'offers': {
            '@type': 'AggregateOffer',
            'lowPrice': Math.min(...prices),
            'highPrice': Math.max(...prices),
            'priceCurrency': 'JPY'
          }
        }
      })
      return JSON.stringify(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.view {
  &__content {
    margin-top: 40px;
    &__detail {
      &__items {
        margin-top: 32px;
        &__title {
          font-size: 2.6rem;
          color: $main-color;
        }
        &__list {
          margin-top: 16px;
        }
      }
    }
  }
  &__button {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__text {
      min-width: 89%;
    }
  }
}

@media screen and (min-width: $min-width-threshold) {

.view {
  &__content {
    &__detail {
      &__description {
        display: none;
      }
      &__items {
        &__title {
          font-size: 3.2rem;
        }
        &__list {
          margin: 16px 4px 0 4px;
        }
      }
    }
  }
  &__button {
    display: none;
  }
}

}
</style>
