import { collection, doc, getDoc, getDocs, query } from 'firebase/firestore'
import { firestore } from '../firebase'
import { setCache, hasCache, getCache } from './cache'
import { parsePath, splitParams } from './helper'

export default class Model {
  /**
   * コレクション
   * @type {CollectionReference<DocumentData>}
   */
  collection
  /**
   * コンテキストパラメータ
   * @type {Object}
   */
  context
  /**
   * ID
   * @type {any}
   */
  id

  /**
   * データのアサイン
   * @param {object} params
   * @returns {Model}
   */
  assign(params) {
    Object.assign(this, params)
    return this
  }

  /**
   * ドキュメントの取得
   * @param {string} id
   * @returns {Promise<Model|null>}
   */
  async getDoc(id) {
    if (hasCache(this.collection.path, id)) {
      return getCache(this.collection.path, id)
    }
    const d = await getDoc(doc(this.collection, id))
    if (!d.exists()) {
      return null;
    }
    this.id = d.id
    this.assign(d.data())
    setCache(this.collection.path, id, this)
    return this
  }

  /**
   * ドキュメントのクエリによる取得
   * @param {Array<Query<T>>} queries where(...)のようなAPIを使用した値を渡す
   * @returns {Promise<array>}
   */
  async getDocs(queries = []) {
    let q = this.collection
    if (queries.length > 0) {
      queries = [this.collection].concat(queries)
      q = query.apply(null, queries)
    }
    const snapshot = await getDocs(q)
    return snapshot.docs.map(d => {
      const m = new this.constructor()
      m.collection = this.collection
      m.context = this.context;
      m.id = d.id
      m.assign(d.data())
      return m
    })
  }

  /**
   * コレクションパスのコンテキスト設定
   * @param {object} params
   * @returns {Model}
   */
  static context(params) {
    const model = new this()
    model.collection = this.collection(params)
    model.context = params
    return model
  }

  /**
   * パスでコンテキスト設定
   * @param {string} path
   * @returns {Model}
   */
  static withPath(path) {
    return this.context(splitParams(this.path, path))
  }

  /**
   * コレクションの取得
   * @param {object} params
   * @returns {CollectionReference<DocumentData>}
   */
  static collection(params) {
    if (!this.path) {
      throw new Error('モデルにpathが定義されていません')
    }
    let path = this.path
    if (params && typeof params === 'object' && Object.keys(params).length > 0) {
      path = parsePath(this.path, params)
    }
    return collection(firestore, path)
  }

  /**
   * ドキュメントの取得
   * @param {string} id
   * @param {object} context
   * @returns {Promise<Model>}
   */
  static async getDoc(id, context = null) {
    let model = this.context(context)
    return await model.getDoc(id)
  }

  /**
   * ドキュメントのクエリによる取得
   * @param {Array<Query<T>>} queries
   * @param {object} context
   * @returns {Promise<array>}
   */
  static async getDocs(queries = [], context = null) {
    let model = this.context(context)
    return await model.getDocs(queries)
  }
}
