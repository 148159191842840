<template>
  <div class="view">
    <div class="view__content">
      <div class="view__content__detail">
        <OrderDetail :data="orders" />
      </div>
      <div class="view__content__block">
        <h3 class="view__content__block__title">ご注文者名</h3>
        <div class="view__content__block__value">
          <p class="view__content__block__value__text">{{ data.name }}</p>
        </div>
      </div>
      <div class="view__content__block">
        <h3 class="view__content__block__title">お届け先住所</h3>
        <div class="view__content__block__value">
          <p class="view__content__block__value__text">{{ zip }}</p>
          <p class="view__content__block__value__text">{{ address }}</p>
        </div>
      </div>
      <div class="view__content__block">
        <h3 class="view__content__block__title">配送方法</h3>
        <div class="view__content__block__value">
          <p class="view__content__block__value__text">{{ deliveryType }}</p>
        </div>
      </div>
      <div v-if="isDelivery" class="view__content__block">
        <h3 class="view__content__block__title">お届け先氏名</h3>
        <div class="view__content__block__value">
          <p class="view__content__block__value__text">{{ deliveryName }}</p>
        </div>
        <MyNote class="view__content__block__note" :type="myNoteTypeKome" :data="deliveryNameNotes" />
      </div>
      <div class="view__content__block">
        <h3 class="view__content__block__title">支払方法</h3>
        <div class="view__content__block__value">
          <p class="view__content__block__value__text">{{ paymentType }}</p>
        </div>
        <MyNote class="view__content__block__note" :type="myNoteTypeKome" :data="paymentTypeNotes" />
      </div>
      <div class="view__content__block">
        <h3 class="view__content__block__title">お届け希望曜日</h3>
        <div class="view__content__block__value">
          <p class="view__content__block__value__text">{{ deliveryDays }}</p>
        </div>
        <MyNote class="view__content__block__note" :type="myNoteTypeKome" :data="deliveryDaysNotes" />
      </div>
      <div class="view__content__block">
        <h3 class="view__content__block__title">お届け希望時間</h3>
        <div class="view__content__block__value">
          <p class="view__content__block__value__text">{{ deliveryTime }}</p>
        </div>
      </div>
      <div v-if="isDirectDelivery" class="view__content__block">
        <h3 class="view__content__block__title">お電話番号</h3>
        <div class="view__content__block__value">
          <p class="view__content__block__value__text">{{ data.tel }}</p>
        </div>
      </div>
      <div class="view__content__block">
        <h3 class="view__content__block__title">メールアドレス</h3>
        <div class="view__content__block__value">
          <p class="view__content__block__value__text">{{ data.email }}</p>
        </div>
        <MyNote class="view__content__block__note" :type="myNoteTypeKome" :data="emailNotes" />
      </div>
      <div class="view__content__block">
        <h3 class="view__content__block__title">ご要望等があればご記入ください</h3>
        <div class="view__content__block__value">
          <p class="view__content__block__value__text view__content__block__note">{{ note }}</p>
        </div>
      </div>
      <MyNote class="view__content__note" :type="myNoteTypeKome" :data="notes" />
      <aside class="view__content__policy">
        ご注文に際しまして、本サイトの<MyLink class="view__content__policy__link" title="サイトポリシー" to="/policy" :external="true" target="_blank" />と<MyLink class="view__content__policy__link" title="特定商取引法に基づく表記" to="/jaosct" :external="true" target="_blank" />をご確認ください。
      </aside>
      <div class="view__content__buttons">
        <MyButton class="view__content__buttons__button"
          title="送信する" :size="myButtonSizeMiddle"
          @on-click="onClickSubmit" />
        <MyButton class="view__content__buttons__button"
          title="キャンセル" :type="myButtonTypeSecondary" :size="myButtonSizeMiddle"
          @on-click="onClickCancel" />
      </div>
    </div>
  </div>
</template>

<script>
import { isDirectDelivery, isDelivery, getDeliveryType, getPaymentType, getDeliveryTime, getDeliveryDay } from '../../utils/constants.js'
import MyButton, { ConstantsMixin as MyButtonConstantsMixin } from '../Atoms/MyButton.vue'
import OrderDetail from '../Presentations/OrderDetail.vue'
import MyNote, { ConstantsMixin as MyNoteConstantsMixin } from '../Atoms/MyNote.vue'
import MyLink from '../Atoms/MyLink.vue'

export default {
  mixins: [MyButtonConstantsMixin, MyNoteConstantsMixin],
  name: 'ConfirmView',
  components: {
    MyButton,
    OrderDetail,
    MyNote,
    MyLink
  },
  props: {
    data: Object,
    orders: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onClickSubmit(e) {
      this.$emit('onClickSubmit', e)
    },
    onClickCancel(e) {
      this.$emit('onClickCancel', e)
    }
  },
  computed: {
    isDirectDelivery() {
      return isDirectDelivery(this.data.deliveryType)
    },
    isDelivery() {
      return isDelivery(this.data.deliveryType)
    },
    zip() {
      return this.data.zip1 + '-' + this.data.zip2
    },
    address() {
      let address = this.data.prefecture + this.data.address
      if (this.data.building) {
        address += '\n' + this.data.building
      }
      return address
    },
    deliveryType() {
      return getDeliveryType(this.data.deliveryType).name
    },
    deliveryName() {
      return this.data.deliveryName || 'ー'
    },
    deliveryNameNotes() {
      return ['未入力の場合はご注文者名でお届けいたします。']
    },
    paymentType() {
      return getPaymentType(this.data.paymentType).name
    },
    paymentTypeNotes() {
      const payment = getPaymentType(this.data.paymentType)
      if (!payment) {
        return []
      }
      if (payment.id === 'BankTransfer') {
        return ['銀行振込時の振込手数料はお客さま負担となります。']
      }
      return []
    },
    deliveryDays() {
      return this.data.deliveryDays.map(v => getDeliveryDay(v).name).join(', ')
    },
    deliveryDaysNotes() {
      return ['収穫状況によってはご希望に添えないことがあります。']
    },
    deliveryTime() {
      return getDeliveryTime(this.data.deliveryTime).name
    },
    emailNotes() {
      return ['info-yamanota@mail.y-mgt.comからメールが送られますので、メールを受け取れるアドレスを入力してください。']
    },
    note() {
      return this.data.note || 'ー'
    },
    notes() {
      return [
        '注文内容はブラウザごとに保存されます。不特定多人数が使用するPC、スマホでのご注文にはご注意ください。'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

.view {
  &__content {
    &__detail {
      margin-bottom: 24px;
    }
    &__block {
      margin-top: 16px;
      &__title {
        color: $font-color-weak;
        font-size: 1.4rem;
      }
      &__value {
        margin: 4px 4px 0 4px;
        padding: 12px 12px;
        background-color: $main-color-light;
        border-radius: 8px;
        &__text {
          color: $font-color;
          font-size: 1.6rem;
          white-space: pre-wrap;
        }
      }
      &__note {
        margin: 8px 8px 0 8px;
      }
    }
    &__note {
      margin-top: 24px;
    }
    &__policy {
      margin-top: 24px;
      font-size: 1.4rem;
    }
    &__buttons {
      margin-top: 64px;
      padding: 0 8px;
      &__button {
        display: block;
        width: 100%;
        &:not(:first-child) {
          margin-top: 16px;
        }
      }
    }
  }
}

@media screen and (min-width: $min-width-threshold) {

.view {
  &__content {
    &__detail {
      margin-left: auto;
      margin-right: auto;
      width: 343px;
    }
    &__block {
      &__title {
        font-size: 1.5rem;
      }
      &__value {
        margin: 8px 8px 0 8px;
        &__text {
          font-size: 1.7rem;
        }
      }
    }
    &__note {
      margin-top: 32px;
    }
    &__policy {
      margin-top: 32px;
      font-size: 1.5rem;
    }
    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      &__button {
        display: initial;
        width: 240px;
        &:not(:first-child) {
          margin-top: 0;
          margin-left: 16px;
        }
      }
    }
  }
}

}
</style>
