<template>
  <fieldset class="section">
    <legend class="section__title" :class="titleClass">{{ title }}</legend>
    <div class="section__content">
      <slot></slot>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: 'FormSection',
  props: {
    title: String,
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    titleClass() {
      const r = []
      if (this.required) {
        r.push('section__title--required')
      }
      return r
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  &__title {
    font-size: 1.8rem;
    color: $main-color;
    &--required:after {
      content: '＜必須＞';
      margin-left: 8px;
      font-size: 1.2rem;
      color: $font-color-danger;
      vertical-align: text-bottom;
    }
  }
  &__content {
    margin-top: 8px;
    padding: 0 8px;
  }
}

@media screen and (min-width: $min-width-threshold) {

.section {
  &__title {
    font-size: 2.0rem;
  }
  &__content {
    margin-top: 16px;
  }
}

}
</style>
