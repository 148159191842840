import Model from './Model'
import Stock from './Stock'

/**
 * 商品構成要素
 */
export default class Usage extends Model {
  /**
   * パス
   * @type {string}
   */
  static path = '/year/:year/plans/:planId/groups/:groupId/products/:productId/usages';

  /**
   * 在庫ID
   * @type {string}
   */
  stockId
  /**
   * 量
   * @type {number}
   */
  amount
  /**
   * 作成日
   * @type {DateTime}
   */
  createdAt

  /**
   * 在庫の取得
   * @returns {Promise<Stock>}
   */
  async getStock() {
    return await Stock.context({ year: this.context.year }).getDoc(this.stockId)
  }
}
