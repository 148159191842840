<template>
  <section class="order">
    <h2 class="order__title">ご注文内容</h2>
    <div class="order__detail">
      <ul v-if="data.length > 0" class="order__detail__list">
        <li v-for="d in data" :key="d.id" class="order__detail__list__item">
          <div class="order__detail__list__item__name">{{ d.item }}</div>
          <div class="order__detail__list__item__price">{{ formatPrice(d.price) }}</div>
        </li>
      </ul>
      <div v-else class="order__detail__nodata">ー</div>
      <div class="order__detail__sum">
        <div class="order__detail__sum__title">合計</div>
        <div class="order__detail__sum__price">{{ formatPrice(sum) }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import UtilMixin from '../common/UtilMixin.js'

export default {
  mixins: [UtilMixin],
  name: 'OrderDetail',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    sum() {
      return this.data.reduce((sum, d) => sum + d.price, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.order {
  border: 1px solid $main-color;
  padding: 16px 12px;
  &__title {
    font-size: 2.0rem;
  }
  &__detail {
    margin: 12px 4px 0 4px;
    &__list {
      padding: 8px 12px 4px 12px;
      background-color: $main-color-light;
      border-bottom: 1px solid $main-color;
      &__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &:not(:first-child) {
          margin-top: 4px;
        }
        &__name {
          font-size: 1.7rem;
        }
        &__price {
          font-size: 1.7rem;
        }
      }
    }
    &__nodata {
      padding: 8px 12px 4px 12px;
      background-color: $main-color-light;
      border-bottom: 1px solid $main-color;
      font-size: 1.7rem;
    }
    &__sum {
      margin-top: 8px;
      padding: 0 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      &__title {
        font-size: 1.7rem;
        font-weight: 600;
        color: $accent-color;
      }
      &__price {
        margin-left: 16px;
        font-size: 1.7rem;
        font-weight: 600;
        color: $accent-color;
      }
    }
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
