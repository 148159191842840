<template>
  <div class="view">
    <div class="view__breadcrumbs">
      <MyBreadcrumbs :links="breadcrumbs" />
    </div>
    <div class="view__concept">
      <AppConcept />
    </div>
  </div>
</template>

<script>
import AppConcept from '../Presentations/AppConcept.vue'
import MyBreadcrumbs from '../Presentations/MyBreadcrumbs.vue'

export default {
  name: 'AboutView',
  components: { AppConcept, MyBreadcrumbs },
  computed: {
    breadcrumbs() {
      return [
        { title: 'ホーム', to: '/' },
        { title: 'このサイトについて' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.view {
  &__breadcrumbs {
    margin: 0 $content-space;
  }
  &__concept {
    margin-top: 12px;
  }
}

@media screen and (min-width: $min-width-threshold) {

.view {
  &__breadcrumbs {
    margin: 0 $content-space-wide;
  }
  &__concept {
    margin-top: 16px;
  }
}

}
</style>
