<template>
  <aside class="note">
    <ul class="note__list">
      <li class="note__list__item" :class="itemClass" v-for="(d, index) in data" :key="index">{{ d }}</li>
    </ul>
  </aside>
</template>

<script>
import { withModifier } from '../../utils/style'

export const Constants = {
  Type: {
    None: 'none',
    Dot: 'dot',
    Kome: 'kome'
  },
}

export const ConstantsMixin = {
  computed: {
    myNoteTypeDot() {
      return Constants.Type.Dot
    },
    myNoteTypeKome() {
      return Constants.Type.Kome
    }
  }
}

export default {
  name: 'MyNote',
  props: {
    type: {
      type: String,
      default: Constants.Type.None,
      validator: v => Object.values(Constants.Type).includes(v)
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    itemClass() {
      return [
        withModifier('note__list__item', this.type)
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.note {
  &__list {
    list-style-position: outside;
    &__item {
      font-size: 1.3rem;
      color: $font-color-weak;
      &--dot {
        text-indent: -1rem;
        margin-left: 1rem;
        &:before {
          content: "・";
        }
      }
      &--kome {
        text-indent: -1rem;
        margin-left: 1rem;
        &:before {
          content: "※";
          margin-right: 4px;
          vertical-align: text-bottom;
        }
      }
    }
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
