<template>
  <FormSection class="form" title="ご要望等があればご記入ください">
    <MyTextArea class="form__textarea" name="note"
      :value="value" :effect="textareaEffect" :attr="attr"
      @on-input="onInput" @on-change="onChange" />
    <MyError class="form__error" :messages="errors" />
  </FormSection>
</template>

<script>
import FormSection from '../Atoms/FormSection.vue'
import MyError from '../Atoms/MyError.vue'
import MyTextArea, { Constants as MyTextAreaConstants } from '../Atoms/MyTextArea.vue'

export default {
  name: 'NoteForm',
  components: { FormSection, MyError, MyTextArea },
  props: {
    value: String,
    errors: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onInput(e) {
      this.$emit('onInputNote', e.target.value)
    },
    onChange(e) {
      this.$emit('onChangeNote', e.target.value)
    }
  },
  computed: {
    textareaEffect() {
      return !this.errors || this.errors.length === 0 ?
        MyTextAreaConstants.Effect.Normal : MyTextAreaConstants.Effect.Error
    },
    attr() {
      return { rows: 6 }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  &__textarea {
    width: 100%;
  }
  &__error {
    margin-top: 8px;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
