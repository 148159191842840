<template>
  <router-link class="logo" to="/"><img src="../../assets/logo.png" alt="やまのた直売所"></router-link>
</template>

<script>
export default {
  name: 'AppLogo',
}
</script>

<style lang="scss" scoped>
.logo {
  box-sizing: border-box;
  cursor: pointer;
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
