/**
 * バリデータ
 */
export default class Validator {
  /**
   * 数字チェック正規表現
   * @type {RegExp}
   */
  static digitRegExp = /^[0-9]+$/
  /**
   * メールアドレス正規表現
   * @type {RegExp}
   */
  static emailRegExp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/

  /**
   * エラーメッセージ
   * @type {array}
   */
  messages = []
  /**
   * ラベル
   * @type {string}
   */
  label
  /**
   * 値
   * @type {any}
   */
  value
  /**
   * チェックの途中離脱を行うか
   * @type {boolean}
   */
  isBroken = false

  /**
   * @param {string} label ラベル名
   * @param {any} value 値
   */
  constructor(label, value) {
    this.label = label
    this.value = value
  }

  /**
   * 有効な値か
   * @returns {boolean}
   */
  isValid() {
    return this.messages.length === 0
  }

  /**
   * バリデーションラッパー
   * @param {any} value
   * @param {string} message
   * @param {Object} option
   * @returns {Validator}
   */
  wrap(valid, message, option) {
    if (this.isBroken) {
      return this
    }
    const isBroken = option.isBroken || false
    if (!valid(this.value)) {
      this.messages.push(message)
      this.isBroken = isBroken
    }
    return this
  }

  /**
   * 必須チェック
   * @param {Object} option
   * @returns {Validator}
   */
  required(option = {}) {
    return this.wrap(
      value => value !== '' && value !== null && value !== undefined,
      option.message || `${this.label}は必須入力です。`,
      option
    )
  }

  /**
   * 等価チェック
   * ==による比較
   * @param {any} v
   * @param {Object} option
   * @returns {Validator}
   */
  equals(v, option = {}) {
    return this.wrap(
      value => value == v,
      option.message || `${this.label}が${v}と一致しません。`,
      option
    )
  }

  /**
   * 文字列長チェック
   * @param {int} size
   * @param {Object} option
   * @returns {Validator}
   */
  length(size, option = {}) {
    return this.wrap(
      value => value.length === size,
      option.message || `${this.label}は${size}文字で入力してください。`,
      option
    )
  }

  /**
   * 最大文字列長チェック
   * @param {int} size
   * @param {Object} option
   * @returns {Validator}
   */
  maxLength(size, option = {}) {
    return this.wrap(
      value => value.length <= size,
      option.message || `${this.label}は${size}文字以下で入力してください。`,
      option
    )
  }

  /**
   * 数字チェック
   * @param {Object} option
   * @returns {Validator}
   */
  isDigit(option = {}) {
    return this.wrap(
      value => value.match(Validator.digitRegExp),
      option.message || `${this.label}は半角数字で入力してください。`,
      option
    )
  }

  /**
   * nubmer型チェック
   * @param {Object} option
   * @returns {Validator}
   */
  isNumber(option = {}) {
    return this.wrap(
      value => typeof value === 'number',
      option.message || `${this.label}は数値で入力してください。`,
      option
    )
  }

  /**
   * array型チェック
   * @param {Object} option
   * @returns {Validator}
   */
  isArray(option = {}) {
    return this.wrap(
      value => Array.isArray(value),
      option.message || `${this.label}は配列で入力してください。`,
      option
    )
  }

  /**
   * 日付文字列かチェック
   * @param {Object} option
   * @returns {Validator}
   */
  isDateString(option = {}) {
    return this.wrap(
      value => !isNaN((new Date(value)).getDate()),
      option.message || `${this.label}は有効な日付で入力してください。`,
      option
    )
  }

  /**
   * 候補チェック
   * @param {array} values 候補
   * @param {Object} option
   * @returns {Validator}
   */
  includes(values, option = {}) {
    return this.wrap(
      value => values.includes(value),
      option.message || `存在しない${this.label}です。`,
      option
    )
  }

  /**
   * 候補チェック（配列）
   * @param {array} values 候補
   * @param {Object} option
   * @returns {Validator}
   */
  memberIncludes(values, option = {}) {
    return this.wrap(
      value => value.every(v => values.includes(v)),
      option.message || `存在しない${this.label}が含まれています。`,
      option
    )
  }

  /**
   * メールアドレスチェック
   * @param {Object} option
   * @returns {Validator}
   */
  email(option = {}) {
    return this.wrap(
      value => value.match(Validator.emailRegExp),
      option.message || `${this.label}はメールアドレス形式で入力してください。`,
      option
    )
  }

  /**
   * カスタムチェック
   * @param {Function} valid
   * @param {string} message
   * @param {Object} option
   * @returns {Validator}
   */
  custom(valid, message, option) {
    return this.wrap(valid, message, option || {})
  }
}
