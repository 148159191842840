import { splitPathAndId } from './helper'
import Model from './Model'
import Plan from './Plan'
import Product from './Product'
import ProductGroup from './ProductGroup'

/**
 * 注文
 *
 * 他のモデルと異なりローカルライブラリからはアクセスできないので、
 * Modelに関わる基本的な機能は使えないと思った方が良い
 *
 * TODO: 支払方法に関する付随情報、決済IDや手数料とか
 */
export default class Order extends Model {
  /**
   * パス
   * @type {string}
   */
  static path = '/orders';

  /**
   * @param {any} data 注文データ
   */
  constructor(data) {
    super()

    Object.assign(this, data)
  }

  /**
   * プランレファレンス
   * @type {Reference}
   */
  planRef
  /**
   * グループレファレンス
   * @type {Reference}
   */
  groupRef
  /**
   * 商品レファレンス
   * @type {Reference}
   */
  productRef
  /**
   * 値段（税込）
   * @type {number}
   */
  price
  /**
   * 配送費（税込）
   * @type {number}
   */
  deliveryPrice
  /**
   * 配送方法
   * @type {number}
   */
  deliveryType
  /**
   * 支払方法
   * @type {number}
   */
  paymentType
  /**
   * 注文者名
   * @type {string}
   */
  name
  /**
   * 郵便番号1
   * @type {string}
   */
  zip1
  /**
   * 郵便番号2
   * @type {string}
   */
  zip2
  /**
   * 都道府県
   * @type {string}
   */
  prefecture
  /**
   * 住所
   * @type {string}
   */
  address
  /**
   * 建物名
   * @type {string}
   */
  building
  /**
   * メールアドレス
   * @type {string}
   */
  email
  /**
   * 電話番号
   * @type {string}
   */
  tel
  /**
   * コメント
   * @type {string}
   */
  note
  /**
   * 希望日
   * @type {Array<number>}
   */
  deliveryDays
  /**
   * 入金確認コード
   * @type {string}
   */
  confirmCode
  /**
   * シークレット
   * @type {string}
   */
  secret
  /**
   * 暗号化ソルト値
   * @type {string}
   */
  salt
  /**
   * 暗号化初期ベクトル
   * @type {string}
   */
  iv
  /**
   * 支払完了
   * @type {boolean}
   */
  paid
  /**
   * 対応完了
   * @type {boolean}
   */
  finished
  /**
   * メール成功
   * @type {boolean}
   */
  mailSuccess
  /**
   * キャンセル
   * @type {boolean}
   */
  canceled
  /**
   * 作成日
   * @type {Object}
   */
  createdAt

  /**
   * プランの取得
   * @returns {Promise<Plan>}
   */
  async getPlan() {
    const [path, id] = splitPathAndId(this.planRef)
    return await Plan.withPath(path).getDoc(id)
  }

  /**
   * プランの取得
   * @returns {Promise<ProductGroup>}
   */
  async getProductGroup() {
    const [path, id] = splitPathAndId(this.groupRef)
    return await ProductGroup.withPath(path).getDoc(id)
  }

  /**
   * 商品の取得
   * @returns {Promise<Product>}
   */
  async getProduct() {
    const [path, id] = splitPathAndId(this.productRef)
    return await Product.withPath(path).getDoc(id)
  }
}
