<template>
  <label class="select">
    <select :name="name" class="select__box" :class="selectClass" @change="onChange">
      <option v-if="withBlank" value=""></option>
      <option v-for="d in data" :key="d" :value="d" :selected="d === value">{{ d }}</option>
    </select>
  </label>
</template>

<script>
import { withModifier } from '../../utils/style'

export const Constants = {
  Effect: {
    Normal: 'normal',
    Error: 'error'
  }
}

export const ConstantsMixin = {
  computed: {
    mySelectBoxEffectNormal() {
      return Constants.Effect.Normal
    },
    mySelectBoxEffectError() {
      return Constants.Effect.Error
    }
  }
}

export default {
  name: 'MySelectBox',
  props: {
    effect: {
      type: String,
      default: Constants.Effect.Normal,
      validator: v => Object.values(Constants.Effect).includes(v)
    },
    name: String,
    value: String,
    data: {
      type: Array,
      default: () => []
    },
    withBlank: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onChange(e) {
      this.$emit('onChange', e)
    },
  },
  computed: {
    selectClass() {
      return [
        withModifier('select__box', this.effect)
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.select {
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    right: 12px;
    top: 0;
    border-bottom: $border-color 2px solid;
    border-right: $border-color 2px solid;
    transform: rotate(45deg);
    pointer-events: none;
  }
  &__box {
    height: 48px;
    padding: 8px 32px 8px 12px;
    border-radius: 8px;
    font-size: 1.6rem;
    color: $font-color;
    &--normal {
      border: 1px solid $border-color;
    }
    &--error {
      border: 1px solid $border-color-danger;
      background-color: $danger-color-weak;
    }
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
