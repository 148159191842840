import { where, documentId } from 'firebase/firestore'
import { dateToYmdNumber, ymdNumberToDate } from '../utils/helper'
import Model from './Model'
import Item from './Item'
import ProductGroup from './ProductGroup'

/**
 * プラン
 */
export default class Plan extends Model {
  /**
   * パス
   * @type {string}
   */
  static path = '/year/:year/plans';

  /**
   * 対象年
   * @type {string}
   */
  year
  /**
   * 区分け
   * single|set|feature
   * @type {string}
   */
  division
  /**
   * プラン名
   * @type {string}
   */
  name
  /**
   * 説明文
   * @type {string}
   */
  description
  /**
   * 説明画像（SPのみ）
   * 画像サイズ: 343x189
   * @type {string}
   */
  descriptionImageUrl
  /**
   * 画像URL（PC、SP共通）
   * 画像サイズ: 136x136
   * @type {string}
   */
  imageUrl
  /**
   * 特別URL（SP）
   * 画像サイズ: 375x437
   * @type {string}
   */
  featureImageUrl
  /**
   * 特別URL（PC）
   * 画像サイズ: 1280x648
   * @type {string}
   */
  featureImageWideUrl
  /**
   * 配送料金
   * @type {number}
   */
  deliveryPrice
  /**
   * 無効
   * @type {boolean}
   */
  disable
  /**
   * 無効時の注記
   * @type {string}
   */
  note
  /**
   * 非表示
   * @type {boolean}
   */
  invisible
  /**
   * 開始年月日（yyyyMMdd）
   * @type {number}
   */
  startYmd
  /**
   * 終了年月日（yyyyMMdd）
   * @type {number}
   */
  endYmd
  /**
   * 構成品目ID
   * @type {array}
   */
  itemIds
  /**
   * ソート番号
   * @type {number}
   */
  sortNum
  /**
   * 販売しているか
   * @type {boolean}
   */
  noSale
  /**
   * 在庫ID
   * @type {Array<string>}
   */
  stockIds
  /**
   * 作成日
   * @type {DateTime}
   */
  createdAt

  /**
   * Date型の開始年月日
   * @returns {Date}
   */
  get startDate() {
    return ymdNumberToDate(this.startYmd)
  }

  /**
   * Date型の終了年月日
   * @returns {Date}
   */
  get endDate() {
    return ymdNumberToDate(this.endYmd)
  }

  /**
   * 無効かどうか
   * @returns {boolean}
   */
  get isDisabled() {
    return this.invisible || this.disable
  }

  /**
   * セール中かどうか
   * @returns {boolean}
   */
  get inSale() {
    const nowYmd = dateToYmdNumber(new Date())
    return this.startYmd <= nowYmd && nowYmd <= this.endYmd
  }

  /**
   * 販売前かどうか
   * @returns {boolean}
   */
  get beforeSale() {
    const nowYmd = dateToYmdNumber(new Date())
    return nowYmd < this.startYmd
  }

  /**
   * 販売後かどうか
   * @returns {boolean}
   */
  get afterSale() {
    const nowYmd = dateToYmdNumber(new Date())
    return this.endYmd < nowYmd
  }

  /**
   * 詳細ページへのURL
   * @returns {string}
   */
  get url() {
    return '/plan/' + this.id;
  }

  /**
   * 表示説明文
   * @return {string}
   */
  get displayedDescription() {
    return this.description.replaceAll('\\n', '\n');
  }

  /**
   * 可視性のあるPlanを取得
   * @returns {Promise<array>}
   */
  async getVisibleDocs() {
    return await this.getDocs([where('invisible', '==', false)])
  }

  /**
   * 品目の取得
   * @returns {Promise<Array<Item>>}
   */
  async getItems() {
    if (!this.itemIds || this.itemIds.length <= 0) {
      return []
    }
    const items = await Item.context({ year: this.year }).getDocs([where(documentId(), 'in', this.itemIds)])
    // inクエリでは順序が保存されないので、自分でソートする
    return this.itemIds.map(itemId => items.find(item => item.id === itemId))
  }

  /**
   * グループの取得
   * @param {Array<Query<T>>} queries
   * @returns {Promise<Array<ProductGroup>>}
   */
  async getGroups(queries = []) {
    return await ProductGroup.context(Object.assign({}, this.context, { planId: this.id })).getDocs(queries)
  }

  /**
   * 購入可能か
   * @returns {Promise<boolean>}
   */
  async canBuy() {
    const groups = await this.getGroups()
    for (const group of groups) {
      if (await group.canBuy()) {
        return true
      }
    }
    return false
  }

  /**
   * ソート用比較関数
   * @param {Plan} a
   * @param {Plan} b
   * @returns number
   */
  static compareForSort(a, b) {
    if (a.disable === b.disable) {
      return a.sortNum - b.sortNum
    } else if (a.disable) {
      return 1
    } else {
      return -1
    }
  }
}
