<template>
  <FormSection class="form" title="配送方法" required>
    <div v-for="deliveryType in deliveryTypes" class="form__radio" :key="deliveryType.value">
      <MyRadio v-if="displayed(deliveryType)"
        name="deliveryType" :value="String(deliveryType.value)" :label="deliveryType.name" :checked="isChecked(1)"
        @on-change="onChage" />
    </div>
    <MyError class="form__error" :messages="errors" />
    <MyNote class="form__note" :type="myNoteTypeKome" :data="description" />
  </FormSection>
</template>

<script>
import DeliveryTypes from '../../../constants/deliveryTypes.json'
import FormSection from '../Atoms/FormSection.vue'
import MyError from '../Atoms/MyError.vue'
import MyRadio from '../Atoms/MyRadio.vue'
import MyNote, { ConstantsMixin as MyNoteConstantsMixin } from '../Atoms/MyNote.vue'

export default {
  mixins: [MyNoteConstantsMixin],
  name: 'DeliveryTypeForm',
  components: { FormSection, MyError, MyRadio, MyNote },
  props: {
    value: Number,
    aroundYuzawa: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    isChecked(value) {
      this.value === value
    },
    displayed(deliveryType) {
      // 湯沢市のみフラグがfalseならば常に表示
      return !deliveryType.onlyYuzawa ||
        (this.aroundYuzawa && deliveryType.onlyYuzawa)
    },
    onChage(e) {
      this.$emit('onChangeDeliveryType', Number(e.target.value))
    }
  },
  computed: {
    deliveryTypes() {
      return DeliveryTypes
    },
    description() {
      if (this.aroundYuzawa) {
        return ['直接引き渡しは湯沢市内のみとなります。']
      }
      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  &__radio {
    &:not(:first-child) {
      margin-top: 6px;
    }
  }
  &__error {
    margin-top: 8px;
  }
  &__note {
    margin-top: 8px;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
