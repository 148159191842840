<template>
  <div class="view">
    <IndicatorCover v-if="isLoading" />
    <div v-if="dataLoaded">
      <div class="view__breadcrumbs">
        <MyBreadcrumbs :links="breadcrumbs" />
      </div>
      <div class="view__component">
        <component :is="component" :data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'firebase/firestore'
import Plan from '../../models/Plan'
import { NotFound } from '../../utils/errors'
import { setupMetaTags } from '../../utils/helper'
import MyBreadcrumbs from '../Presentations/MyBreadcrumbs.vue'
import IndicatorCover from '../Presentations/IndicatorCover.vue'
import GeneralView from './Plan/GeneralView.vue'

/**
 * コンポーネントの取得
 * @param {string} planId
 */
function getComponent(plan) {
  void plan
  return 'GeneralView'
}

/**
 * データの取得
 * @param {string} year
 * @param {string} planId
 * @returns {Promise<Object>}
 */
async function fetchData(year, planId) {
  const plan = await Plan.context({ year }).getDoc(planId)
  if (!plan || plan.invisible) {
    throw NotFound()
  }
  const items = await plan.getItems();
  const groups = await plan.getGroups([orderBy('sortNum')]);
  for (const group of groups) {
    const products = await group.getProducts([orderBy('sortNum')])
    // groupにproductをぶら下げる
    group.products = products
  }
  // 販売していない状態の場合は強制的に売り切れ扱い
  // canBuyは複数の通信が走る処理なので、可能な限り動かしたくない
  const soldOut = plan.noSale || plan.beforeSale || plan.afterSale || !await plan.canBuy()
  return { plan, items, groups, soldOut }
}

export default {
  components: {
    IndicatorCover,
    MyBreadcrumbs,
    GeneralView
  },
  name: 'PlanView',
  props: {
    planId: String
  },
  data() {
    return {
      isLoading: true,
      data: null,
      dataLoaded: false
    }
  },
  watch: {
    $route() {
      location.reload()
    }
  },
  computed: {
    breadcrumbs() {
      return [
        { title: 'ホーム', to: '/' },
        { title: this.data.plan.name }
      ]
    },
    component() {
      return getComponent(this.data.plan)
    }
  },
  created() {
    fetchData((new Date()).getFullYear(), this.planId)
        .then(data => {
          const keywords = [data.plan.name].concat(data.groups.map(d => d.name))
          setupMetaTags(data.plan.name + ' | やまのた直売所', data.plan.displayedDescription, keywords);
          this.data = data
          this.dataLoaded = true
          this.isLoading = false
        })
        .catch(e => {
          console.error(e)
          this.$router.replace('/404')
        })
  }
}
</script>

<style lang="scss" scoped>
.view {
  &__breadcrumbs {
    margin: 0 $content-space;
  }
  &__component {
    margin-top: 12px;
  }
}

@media screen and (min-width: $min-width-threshold) {

.view {
  &__breadcrumbs {
    margin: 0 $content-space-wide;
  }
  &__component {
    margin-top: 16px;
  }
}

}
</style>
