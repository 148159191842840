<template>
  <label class="radio">
    <input class="radio__input" type="radio"
      :name="name" :value="value" :checked="checked" @change="onChange">
    <div class="radio__sign">
      <div class="radio__sign__in"></div>
    </div>
    <div v-if="label" class="radio__label">{{ label }}</div>
  </label>
</template>

<script>
export default {
  name: 'MyRadio',
  props: {
    name: String,
    value: String,
    checked: {
      type: Boolean,
      default: false
    },
    label: String
  },
  methods: {
    onChange(e) {
      this.$emit('onChange', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.radio {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &__sign {
    width: 28px;
    height: 28px;
    border: 1px solid $accent-color-weak;
    border-radius: 14px;
    background-color: $base-color;
    &__in {
      margin: 5px;
      width: 16px;
      height: 16px;
      border-radius: 8px;
    }
  }
  &__label {
    margin-left: 8px;
    line-height: 28px;
    font-size: 1.6rem;
    color: $font-color;
  }
}
.radio__input:checked {
  &+ .radio__sign {
    border: 1px solid $accent-color !important;
    &> .radio__sign__in {
      background-color: $accent-color;
    }
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
