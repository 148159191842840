<template>
  <div class="menu">
    <div class="menu__title">{{ title }}</div>
    <ul class="menu__content">
      <li class="menu__content__item" v-for="plan in plans" :key="plan.id">
        <div v-if="plan.isDisabled" class="menu__content__item__text">{{ plan.name }}</div>
        <MyLink v-else class="menu__content__item__link" :to="plan.url" :title="plan.name" />
      </li>
    </ul>
  </div>
</template>

<script>
import MyLink from '../Atoms/MyLink.vue'

export default {
  name: 'ItemMenu',
  components: { MyLink },
  props: {
    title: String,
    plans: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  &__title {
    font-size: 1.4rem;
    color: $font-color;
  }
  &__content {
    margin-top: 16px;
    padding: 0 8px;
    &__item {
      &:not(:first-child) {
        margin-top: 16px;
      }
      &__text {
        font-size: 1.6rem;
        color: $font-color-weak;
      }
      &__link {
        font-size: 1.6rem;
      }
    }
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
