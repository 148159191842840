<template>
  <fieldset class="section">
    <legend class="section__label">{{ label }}</legend>
    <slot></slot>
  </fieldset>
</template>

<script>
export default {
  name: 'InputSection',
  props: {
    label: String
  }
}
</script>

<style lang="scss" scoped>
.section {
  &__label {
    margin-bottom: 4px;
    font-size: 1.3rem;
    color: $font-color-weak;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
