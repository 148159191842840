<template>
  <div class="list">
    <div v-for="item in items" :key="item.id" class="list__block">
      <img class="list__block__img" :src="item.imageItemUrl" :alt="item.name">
      <img class="list__block__imgwide" :src="item.imageItemWideUrl" :alt="item.name">
      <div class="list__block__content">
        <div class="list__block__content__title">{{ item.name }}</div>
        <div class="list__block__content__description">{{ item.displayedDescription }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemList',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  &__block {
    display: flex;
    flex-direction: row;
    &:not(:first-child) {
      margin-top: 16px;
    }
    &__img {
      display: block;
    }
    &__imgwide {
      display: none;
    }
    &__content {
      margin-left: 12px;
      padding: 4px 0;
      &__title {
        font-size: 1.7rem;
        font-weight: 500;
        color: $font-color;
      }
      &__description {
        margin-top: 8px;
        font-size: 1.5rem;
        color: $font-color;
        line-height: 2.2rem;
        text-align: start;
        white-space: pre-wrap;
      }
    }
  }
}

@media screen and (min-width: $min-width-threshold) {

.list {
  display: flex;
  flex-direction: row;
  &__block {
    flex-direction: column;
    width: 338px;
    &:not(:first-child) {
      margin-top: 0;
      margin-left: 32px;
    }
    &__img {
      display: none;
    }
    &__imgwide {
      display: block;
    }
    &__content {
      margin: 12px 8px 0 8px;
      padding: 0;
      &__title {
        font-size: 2.0rem;
      }
      &__description {
        margin-top: 12px;
        font-size: 1.6rem;
        line-height: 2.3rem;
      }
    }
  }
}

}
</style>
