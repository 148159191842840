<template>
  <FormSection class="form" title="お届け希望時間" required>
    <div v-for="deliveryTime in deliveryTimes" class="form__radio" :key="deliveryTime.value">
      <MyRadio v-if="displayed(deliveryTime)"
        name="deliveryTime" :value="String(deliveryTime.value)" :label="deliveryTime.name" :checked="isChecked(deliveryTime.value)"
        @on-change="onChage" />
    </div>
    <MyError class="form__error" :messages="errors" />
    <MyNote class="form__note" :type="myNoteTypeKome" :data="description" />
  </FormSection>
</template>

<script>
import DeliveryTimes from '../../../constants/deliveryTimes.json'
import FormSection from '../Atoms/FormSection.vue'
import MyError from '../Atoms/MyError.vue'
import MyRadio from '../Atoms/MyRadio.vue'
import MyNote, { ConstantsMixin as MyNoteConstantsMixin } from '../Atoms/MyNote.vue'

export default {
  mixins: [MyNoteConstantsMixin],
  name: 'DeliveryTimeForm',
  components: { FormSection, MyError, MyRadio, MyNote },
  props: {
    value: Number,
    isDelivery: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    isChecked(value) {
      return this.value === value
    },
    displayed(deliveryTime) {
      // 配達方法が宅配便の場合
      return !deliveryTime.onlyDelivery || 
        (this.isDelivery && deliveryTime.onlyDelivery)
    },
    onChage(e) {
      this.$emit('onChangeDeliveryTime', Number(e.target.value))
    }
  },
  computed: {
    deliveryTimes() {
      return DeliveryTimes
    },
    description() {
      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  &__radio {
    &:not(:first-child) {
      margin-top: 6px;
    }
  }
  &__error {
    margin-top: 8px;
  }
  &__note {
    margin-top: 8px;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
