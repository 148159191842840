<template>
  <input ref="input"
    class="input" :class="inputClass"
    :name="name" :type="type" :value="value"
    v-bind="attr"
    @change="onChange" @input="onInput">
</template>

<script>
import { withModifier } from '../../utils/style'

export const Constants = {
  Type: {
    Text: 'text',
    Email: 'email',
    Search: 'search',
    Tel: 'tel',
    Url: 'url',
    Date: 'date'
  },
  Effect: {
    Normal: 'normal',
    Error: 'error'
  }
}

export const ConstantsMixin = {
  computed: {
    myInputTypeText() {
      return Constants.Type.Text
    },
    myInputTypeEmail() {
      return Constants.Type.Email
    },
    myInputTypeSearch() {
      return Constants.Type.Search
    },
    myInputTypeTel() {
      return Constants.Type.Tel
    },
    myInputTypeUrl() {
      return Constants.Type.Url
    },
    myInputEffectNormal() {
      return Constants.Effect.Normal
    },
    myInputEffectError() {
      return Constants.Effect.Error
    }
  }
}

export default {
  name: 'MyInput',
  props: {
    type: {
      type: String,
      default: Constants.Type.Text,
      validator: v => Object.values(Constants.Type).includes(v)
    },
    effect: {
      type: String,
      default: Constants.Effect.Normal,
      validator: v => Object.values(Constants.Effect).includes(v)
    },
    name: String,
    value: String,
    attr: Object
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    onChange(e) {
      this.$emit('onChange', e)
    },
    onInput(e) {
      this.$emit('onInput', e)
    }
  },
  computed: {
    inputClass() {
      return [
        withModifier('input', this.effect)
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  height: 48px;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 1.6rem;
  color: $font-color;
  &::placeholder {
    color: #aaa;
  }
  &[disabled] {
    background-color: #e7e7e7;
  }
  &--normal {
    border: 1px solid $border-color;
  }
  &--error {
    border: 1px solid $border-color-danger;
    background-color: $danger-color-weak;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
