<template>
  <label class="check">
    <input class="check__input" type="checkbox"
      :name="name" :value="value" :checked="checked" @change="onChange">
    <div class="check__sign">
      <div class="check__sign__in"></div>
    </div>
    <div v-if="label" class="check__label">{{ label }}</div>
  </label>
</template>

<script>
export default {
  name: 'MyCheck',
  props: {
    name: String,
    value: String,
    checked: {
      type: Boolean,
      default: false
    },
    label: String
  },
  methods: {
    onChange(e) {
      this.$emit('onChange', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.check {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &__sign {
    width: 28px;
    height: 28px;
    border: 1px solid $accent-color-weak;
    &__in {
      margin-top: 2px;
      margin-left: 8px;
      width: 10px;
      height: 18px;
      transform: rotate(45deg);
    }
  }
  &__label {
    margin-left: 8px;
    line-height: 28px;
    font-size: 1.6rem;
    color: $font-color;
  }
}
.check__input:checked {
  &+ .check__sign {
    border: 1px solid $accent-color !important;
    &> .check__sign__in {
      border-bottom: 3px solid $accent-color;
      border-right: 3px solid $accent-color;
    }
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
