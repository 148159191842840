import { dateToYmdNumber } from '../utils/helper'
import Model from './Model'
import Product from './Product'

/**
 * グループ
 */
export default class ProductGroup extends Model {
  /**
   * パス
   * @type {string}
   */
  static path = '/year/:year/plans/:planId/groups'

  /**
   * グループ名
   * @type {string}
   */
  name
  /**
   * 説明文
   * @type {string}
   */
  description
  /**
   * 画像URL（PC、SP共通）
   * 画像サイズ: 106x128
   * @type {string}
   */
  imageUrl
  /**
   * ソート番号
   * @type {number}
   */
  sortNum
  /**
   * 注釈
   * @type {string}
   */
  note
  /**
   * 収穫期間
   * @type {string}
   */
  deliveryPeriod
  /**
   * 開始年月日（yyyyMMdd）
   * @type {number}
   */
  startYmd
  /**
   * 終了年月日（yyyyMMdd）
   * @type {number}
   */
  endYmd
  /**
   * 無効
   * @type {boolean}
   */
  disable

  /**
   * 表示説明文
   * @returns {string}
   */
  get displayedDescription() {
    return this.description.replaceAll('\\n', '\n');
  }

  /**
   * 販売前かどうか
   * @returns {boolean}
   */
  get beforeSale() {
    const nowYmd = dateToYmdNumber(new Date())
    return nowYmd < this.startYmd
  }

  /**
   * 販売後かどうか
   * @returns {boolean}
   */
  get afterSale() {
    const nowYmd = dateToYmdNumber(new Date())
    return this.endYmd < nowYmd
  }

  /**
   * 購入可能か
   * @returns {Promise<boolean>}
   */
  async canBuy() {
    const products = await this.getProducts()
    for (const product of products) {
      // 一つでも購入可能な商品があれば購入可能とする
      if (await product.canBuy()) {
        return true
      }
    }
    return false
  }

  /**
   * 商品の取得
   * @param {Array<Query<T>>} queries
   * @returns {Promise<Array<Product>>}
   */
  async getProducts(queries = []) {
    return await Product.context(Object.assign({}, this.context, { groupId: this.id })).getDocs(queries)
  }
}
