<template>
  <div class="thumbnail" :class="thumbnailClass">
    <img class="thumbnail__img" :class="thumbnailImgClass" :src="src" :alt="alt">
    <p v-if="description" class="thumbnail__description">{{ description }}</p>
  </div>
</template>

<script>
import { withModifier } from '../../utils/style'

export const Constants = {
  Size: {
    Large: 'large',
    Small: 'small'
  },
  Effect: {
    Grayscale: 'grayscale'
  }
}

export const ConstantsMixin = {
  computed: {
    roundThumbnailSizeLarge() {
      return Constants.Size.Large
    },
    roundThumbnailSizeSmall() {
      return Constants.Size.Small
    },
    roundThumbnailEffectGrayscale() {
      return Constants.Effect.Grayscale
    }
  }
}

export default {
  name: 'RoundThumbnail',
  props: {
    src: String,
    alt: String,
    description: String,
    size: {
      type: String,
      default: Constants.Size.Large,
      validator: v => Object.values(Constants.Size).includes(v)
    },
    effect: {
      type: String,
      validator: v => Object.values(Constants.Effect).includes(v)
    }
  },
  computed: {
    thumbnailClass() {
      return [
        withModifier('thumbnail', this.size)
      ]
    },
    thumbnailImgClass() {
      if (!this.effect) {
        return [];
      }
      return [
        withModifier('thumbnail__img', this.effect)
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail {
  position: relative;
  &__img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 50%;
    background-color: $base-color-dark;
    font-size: 1.0rem;
    &--grayscale {
      filter: grayscale(100%);
    }
  }
  &__description {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: $font-color-flipped;
    text-shadow: 2px 2px 0 $font-color, -2px -2px 0 $font-color,
                -2px 2px 0 $font-color,  2px -2px 0 $font-color,
                   0 2px 0 $font-color,    0 -2px 0 $font-color,
                -2px   0 0 $font-color,  2px    0 0 $font-color;
  }
  &--large {
    width: 96px;
    height: 96px;
  }
  &--small {
    width: 48px;
    height: 48px;
  }
}

@media screen and (min-width: $min-width-threshold) {

.thumbnail {
  &__description {
    font-size: 1.8rem;
  }
  &--large {
    width: 136px;
    height: 136px;
  }
}

}
</style>
