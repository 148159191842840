<template>
  <div class="view">
    <div class="view__breadcrumbs">
      <MyBreadcrumbs :links="breadcrumbs" />
    </div>
    <div class="view__content">
      <h1 class="view__content__title">サイトポリシー</h1>
      <div class="view__content__body">
        <!--
        <div class="view__content__body__block">
          <h2 class="view__content__body__block__title">お客様へ</h2>
          <p class="view__content__body__block__paragraph">
            本サイトでは可能な限りお客様のご希望に応えるよう努めておりますが、
            取り扱っている商品の関係上、ご希望に添えない場合がございますことをご了承ください。
          </p>
        </div>
        -->
        <div class="view__content__body__block">
          <h2 class="view__content__body__block__title">ご利用上注意</h2>
          <p class="view__content__body__block__paragraph">
            本サイトは予告なくサービスの一時的な停止、および終了する可能性があります。
          </p>
          <p class="view__content__body__block__paragraph">
            利用者は本サイトの利用を自身の責任において利用するものとし、
            利用者が本サイトを利用してなされた一切の行為およびその結果に対して、本サイトの開発元は責任を負うものではありません。
          </p>
          <p class="view__content__body__block__paragraph">
            本サイトではサービス提供のため個人情報を取得、利用させていただきます。
          </p>
        </div>
        <div class="view__content__body__block">
          <h2 class="view__content__body__block__title">個人情報</h2>
          <p class="view__content__body__block__paragraph">
            本サイトは個人情報を以下の目的で利用いたします。<br>
            ・サービス提供のため<br>
            ・サービス運用していく上で必要な連絡、請求<br>
            ・マーケティング調査、統計、分析<br>
            ・ご利用者からのお問い合わせ等への対応<br>
            ・本サービスに関するお知らせ、告知のため<br>
          </p>
          <p class="view__content__body__block__paragraph">
            本サイトで取得した個人情報は、お客様の同意を得ずに利用目的の範囲を超えて個人情報を扱いません。<br>
            ただし、以下の場合はその限りではありません。<br>
            ・裁判所、警察、その他機関から法令に基づき開示を求められた場合<br>
            ・人命、財産保護のため必要がある場合で、お客様の同意が困難である場合
          </p>
          <p class="view__content__body__block__paragraph">
            取得した個人情報は、適正かつ安全な方法で取り扱います。
          </p>
        </div>
        <div class="view__content__body__block">
          <h2 class="view__content__body__block__title">サイトポリシーの変更</h2>
          <p class="view__content__body__block__paragraph">
            本サイトポリシーの内容は変更することがあります。
          </p>
        </div>
        <div class="view__content__body__block">
          <h2 class="view__content__body__block__title">お問い合わせ</h2>
          <p class="view__content__body__block__paragraph">
            お問い合わせは下記のアドレスまでお送りください。<br>
            yuji.yamagata2@gmail.com
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyBreadcrumbs from '../Presentations/MyBreadcrumbs.vue'

export default {
  name: 'PolicyView',
  components: { MyBreadcrumbs },
  computed: {
    breadcrumbs() {
      return [
        { title: 'ホーム', to: '/' },
        { title: 'サイトポリシー' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.view {
  &__breadcrumbs {
    margin: 0 $content-space;
  }
  &__content {
    margin: 8px calc($content-space + 4px) 0 calc($content-space + 4px);
    &__title {
      font-size: 2.6rem;
      color: $main-color;
    }
    &__body {
      margin-top: 24px;
      padding: 0 4px;
      &__block {
        &:not(:first-child) {
          margin-top: 12px;
        }
        &__title {
          font-size: 1.8rem;
          color: $font-color;
          font-weight: 500;
        }
        &__paragraph {
          margin-top: 8px;
          font-size: 1.4rem;
          color: $font-color;
          line-height: 2.2rem;
        }
      }
    }
  }
}

@media screen and (min-width: $min-width-threshold) {

.view {
  &__breadcrumbs {
    margin: 0 $content-space-wide;
  }
  &__content {
    margin: 32px calc($content-space-wide + 8px) 0 calc($content-space-wide + 8px);
    &__title {
      font-size: 3.6rem;
    }
    &__body {
      padding: 0 8px;
      &__paragraph {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }
}

}
</style>
