<template>
  <FormSection class="form" title="商品" required>
    <ul v-if="groups.length > 1" class="form__tabs">
      <li v-for="group in groups" :key="group.id" class="form__tabs__item">
        <ToggleButton :title="group.name" :selected="isSelectedGroup(group.id)" @on-click="onClickGroup(group.id)" />
      </li>
    </ul>
    <div v-for="group in groups" :key="group.id">
      <transition name="fade">
        <div v-show="isSelectedGroup(group.id)" class="form__section">
          <div class="form__section__profile">
            <img class="form__section__profile__img" :src="group.imageUrl" :alt="group.name">
            <div class="form__section__profile__info">
              <div class="form__section__profile__info__title">{{ group.name }}</div>
              <MyNote :data="[group.note]" />
            </div>
          </div>
          <ul class="form__section__list">
            <li v-for="product in group.products"
                :key="product.id"
                class="form__section__list__item"
                :class="productClass(product.id)"
                @click="onChangeProduct(product.id)">
              <MyRadio name="product" :value="product.id" :label="product.name" :checked="isSelectedProduct(product.id)" />
              <div class="form__section__list__item__price">{{ formatPrice(product.price) }}</div>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <MyError class="form__error" :messages="errors" />
    <MyNote class="form__note" :type="myNoteTypeKome" :data="description" />
  </FormSection>
</template>

<script>
import UtilMixin from '../common/UtilMixin.js'
import FormSection from '../Atoms/FormSection.vue'
import MyNote, { ConstantsMixin as MyNoteConstantsMixin } from '../Atoms/MyNote.vue'
import MyRadio from '../Atoms/MyRadio.vue'
import MyError from '../Atoms/MyError.vue'
import ToggleButton from '../Atoms/ToggleButton.vue'

export default {
  mixins: [UtilMixin, MyNoteConstantsMixin],
  name: 'ItemForm',
  components: { FormSection, MyNote, MyRadio, MyError, ToggleButton },
  props: {
    groups: {
      type: Array,
      default: () => []
    },
    selected: String,
    errors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedGroup: null,
    }
  },
  methods: {
    isSelectedGroup(id) {
      return this.selectedGroup === id;
    },
    isSelectedProduct(id) {
      return this.selected === id;
    },
    productClass(id) {
      return {
        'form__section__list__item--selected': this.isSelectedProduct(id)
      }
    },
    onClickGroup(id) {
      this.selectedGroup = id;
    },
    onChangeProduct(id) {
      this.$emit('onChangeProduct', id)
    }
  },
  computed: {
    description() {
      return ['写真は商品のイメージです。']
    }
  },
  created() {
    if (this.groups.length <= 0) {
      return;
    }
    this.selectedGroup = this.groups[0].id;
  }
}
</script>

<style lang="scss" scoped>
.form {
  &__tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    &__item {
      &:not(:first-child) {
        margin-left: 12px;
      }
    }
  }
  &__section {
    margin-top: 16px;
    padding: 12px;
    border: 1px solid $main-color;
    &__profile {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      &__img {
        display: block;
        width: 106px;
        height: 128px;
        font-size: 1.0rem;
      }
      &__info {
        margin-left: 12px;
        padding: 4px 0;
        &__title {
          font-size: 1.7rem;
        }
      }
    }
    &__list {
      margin-top: 12px;
      &__item {
        padding: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $main-color;
        cursor: pointer;
        &:not(:first-child) {
          margin-top: 12px;
        }
        &--selected {
          background-color: $main-color-light;
        }
      }
    }
  }
  &__error {
    margin-top: 8px;
  }
  &__note {
    margin-top: 8px;
  }
}

.fade {
  &-enter-active {
    transition: opacity 1.00s ease;
  }
  &-enter-from, &-leave-to {
    opacity: 0;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
