<template>
  <button class="button" @click="onClick"></button>
</template>

<script>
export default {
  name: 'CrossButton',
  methods: {
    onClick(e) {
      this.$emit('onClick', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  display: block;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 89%;
    background: $border-color;
    transform: translate(-50%,-50%) rotate(45deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 89%;
    background: $border-color;
    transform: translate(-50%,-50%) rotate(-45deg);
  }
  &:active {
    opacity: 22.2%;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
