import Model from './Model'

/**
 * 現在販売量
 * シャーディングを行う
 */
export default class Charge extends Model {
  /**
   * パス
   * @type {string}
   */
  static path = '/year/:year/stocks/:stockId/charges';

  /**
   * 量
   * @type {number}
   */
  amount
}
