<template>
  <FormSection class="form" title="ご注文者名" required>
    <MyInput class="form__input" name="name" :value="value" :effect="inputEffect" @on-input="onInput" @on-change="onChange" />
    <MyError class="form__error" :messages="errors" />
  </FormSection>
</template>

<script>
import FormSection from '../Atoms/FormSection.vue'
import MyError from '../Atoms/MyError.vue'
import MyInput, { Constants as MyInputConstants } from '../Atoms/MyInput.vue'

export default {
  name: 'NameForm',
  components: { FormSection, MyError, MyInput },
  props: {
    value: String,
    errors: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onInput(e) {
      this.$emit('onInputName', e.target.value)
    },
    onChange(e) {
      this.$emit('onChangeName', e.target.value)
    }
  },
  computed: {
    inputEffect() {
      return !this.errors || this.errors.length === 0 ?
        MyInputConstants.Effect.Normal : MyInputConstants.Effect.Error
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  &__input {
    width: 100%;
  }
  &__error {
    margin-top: 8px;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
