/**
 * コレクションパスの展開
 * @param {string} path
 * @param {object} params
 * @returns {string}
 */
export function parsePath(path, params) {
    if (path.startsWith('/')) {
      path = path.substring(1)
    }
    let paths = path.split('/')
    paths = paths.map(p => {
      if (p.startsWith(':')) {
        const key = p.substring(1)
        if (key in params) {
          return new String(params[key])
        }
      }
      return p
    })
    return '/' + paths.join('/')
}

/**
 * パスからパラメータを抽出する
 * @param {string} format
 * @param {string} path
 * @returns {object}
 */
export function splitParams(format, path) {
  if (format.startsWith('/')) {
      format = format.substring(1)
  }
  if (path.startsWith('/')) {
      path = path.substring(1)
  }
  const formatSegments = format.split('/')
  const pathSegments = path.split('/')
  const params = {}
  for (let i = 0; i < formatSegments.length; ++i) {
    if (i >= pathSegments.length) {
      break
    }
    if (formatSegments[i].startsWith(':')) {
      const key = formatSegments[i].substring(1)
      params[key] = pathSegments[i]
    }
  }
  return params
}

/**
 * パス部分とID部分に分割する
 * @param {string} path
 * @returns {Array<string>} パス,ID
 */
export function splitPathAndId(path) {
  const index = path.lastIndexOf('/')
  return [path.substring(0, index), path.substring(index + 1)]
}
