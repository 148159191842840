<template>
  <div class="view">
    <div class="view__carousel">
      <MyCarousel :slides="slides" />
    </div>
    <div class="view__content">
      <MyIndicator v-if="isLoading" class="view__content__indicator" />
      <div v-else>
        <ThemeSection v-if="hasFeature" class="view__content__plans" title="特集">
          <IconList :data="featureData" />
        </ThemeSection>
        <ThemeSection v-if="hasSingle" class="view__content__plans" title="商品">
          <IconList :data="singleData" />
        </ThemeSection>
        <ThemeSection v-if="hasSet" class="view__content__plans" title="セット商品">
          <IconList :data="setData" />
        </ThemeSection>
      </div>
    </div>
  </div>
</template>

<script>
import Division from '../../../constants/division.json'
import { resrcUrl } from '../../utils/helper'
import Plan from '../../models/Plan'
import ThemeSection from '../Atoms/ThemeSection.vue'
import MyCarousel, { Constants as MyCarouselConstants } from '../Presentations/MyCarousel.vue'
import IconList from '../Presentations/IconList.vue'

/**
 * デフォルトデータの取得
 * @returns {Object}
 */
function defaultData() {
  return Object.fromEntries(Object.values(Division).map(div => [div, []]))
}

/**
 * データの取得
 * @param {string} year
 * @returns {Promise<Object>}
 */
async function fetchData(year) {
  const plans = await Plan.context({ year }).getVisibleDocs()
  plans.sort(Plan.compareForSort)
  const validDivisions = Object.values(Division)
  const data = defaultData()
  for (const plan of plans) {
    if (!validDivisions.includes(plan.division)) {
      continue
    }
    const datum = {
      id: plan.id,
      src: plan.imageUrl,
      alt: plan.name,
      link: plan.url,
      title: plan.name,
      disabled: plan.isDisabled,
      description: plan.isDisabled ? plan.note : null,
    }
    data[plan.division].push(datum)
  }
  return data
}

export default {
  name: 'TopView',
  components: { ThemeSection, MyCarousel, IconList },
  data() {
    return {
      data: defaultData(),
      isLoading: true
    }
  },
  computed: {
    slides() {
      // 画像サイズについて
      // imageUrl: 375x437
      // imageWideUrl: 1280x648
      return [
        {
          imageUrl: resrcUrl('top.jpg'),
          imageWideUrl: resrcUrl('top-wide.jpg'),
          title: '湯沢の食\nいかがですか?',
          description: 'ここでは地元、湯沢の食の共有をテーマに\n農産物を販売しております',
          size: MyCarouselConstants.Size.Small
        },
        {
          imageUrl: resrcUrl('edamame-feature.jpg'),
          imageWideUrl: resrcUrl('edamame-feature-wide.jpg'),
          title: '枝豆',
          description: '夏の定番、枝豆でカンパイ！\n綺麗な緑に包まれた旨みたっぷりの枝豆\n仕事終わりの一夜、好きなお酒とご一緒に'
        },
        {
          imageUrl: resrcUrl('top-qr.png'),
          imageWideUrl: resrcUrl('top-qr-wide.png'),
          linkUrl: 'https://line.me/R/ti/p/%40666lpjlf'
        }
      ]
    },
    singleData() {
      return this.data[Division.Single]
    },
    setData() {
      return this.data[Division.Set]
    },
    featureData() {
      return this.data[Division.Feature]
    },
    hasSingle() {
      return this.data[Division.Single].length > 0
    },
    hasSet() {
      return this.data[Division.Set].length > 0
    },
    hasFeature() {
      return this.data[Division.Feature].length > 0
    }
  },
  created() {
    fetchData((new Date()).getFullYear())
        .then(data => this.data = data)
        .catch(e => console.error(e))
        .finally(() => this.isLoading = false)
  }
}
</script>

<style lang="scss" scoped>
.view {
  &__content {
    margin-top: 40px;
    &__indicator {
      max-height: 128px;
    }
    &__plans {
      &:not(:first-child) {
        margin-top: 24px;
      }
    }
  }
}

@media screen and (min-width: $min-width-threshold) {

.view {
  &__content {
    &__plans {
      &:not(:first-child) {
        margin-top: 40px;
      }
    }
  }
}

}
</style>
