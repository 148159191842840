<template>
  <header class="header">
    <AppLogo class="header__logo" />
    <div class="header__menu">
      <div class="header__menu__default">
        <button class="header__menu__default__icon" @click="openDrawer">
          <img src="../../assets/hamburger.svg" alt="メニュー">
        </button>
        <transition name="slide">
          <nav v-if="isOpenDrawer" class="header__menu__default__nav">
            <div class="header__menu__default__nav__wrap">
              <div class="header__menu__default__nav__wrap__block">
                <div class="header__menu__default__nav__wrap__block__item">
                  <MyLink class="header__menu__default__nav__wrap__block__item__link" to="/about" title="このサイトについて" />
                </div>
                <div class="header__menu__default__nav__wrap__block__item">
                  <MyLink class="header__menu__default__nav__wrap__block__item__link" to="/policy" title="サイトポリシー" />
                </div>
                <div class="header__menu__default__nav__wrap__block__item">
                  <div v-if="isLoading">
                    <MyIndicator />
                  </div>
                  <div v-else>
                    <ItemMenu class="header__menu__default__nav__wrap__block__item__menu" v-if="hasSingle" title="商品" :plans="singleData" />
                    <ItemMenu class="header__menu__default__nav__wrap__block__item__menu" v-if="hasSet" title="セット商品" :plans="setData" />
                    <ItemMenu class="header__menu__default__nav__wrap__block__item__menu" v-if="hasFeature" title="特集" :plans="featureData" />
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </transition>
        <CrossButton v-if="isOpenDrawer" class="header__menu__default__close" @on-click="closeDrawer" />
        <div v-if="isOpenDrawer" class="header__menu__default__background" @click="closeDrawer"></div>
      </div>
      <nav class="header__menu__wide">
        <ul class="header__menu__wide__list">
          <li class="header__menu__wide__list__item" :class="singleMenuItemClass">
            商品<PopupItemMenu class="header__menu__wide__list__item__popup" :plans="singleData" />
          </li>
          <li class="header__menu__wide__list__item" :class="setMenuItemClass">
            セット商品<PopupItemMenu class="header__menu__wide__list__item__popup" :plans="setData" />
          </li>
          <li class="header__menu__wide__list__item" :class="featureMenuItemClass">
            特集<PopupItemMenu class="header__menu__wide__list__item__popup" :plans="featureData" />
          </li>
          <router-link to="/about"><li class="header__menu__wide__list__item header__menu__wide__list__item--active">このサイトについて</li></router-link>
          <router-link to="/policy"><li class="header__menu__wide__list__item header__menu__wide__list__item--active ">サイトポリシー</li></router-link>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import Division from '../../../constants/division.json'
import { withModifier } from '../../utils/style'
import Plan from '../../models/Plan'
import AppLogo from '../Atoms/AppLogo.vue'
import CrossButton from '../Atoms/CrossButton.vue'
import MyLink from '../Atoms/MyLink.vue'
import ItemMenu from '../Presentations/ItemMenu.vue'
import PopupItemMenu from '../Presentations/PopupItemMenu.vue'

/**
 * デフォルトデータ
 * @returns {Object}
 */
function defaultData() {
  return Object.fromEntries(Object.values(Division).map(div => [div, []]));
}

/**
 * メニューアイテムクラスの取得
 * @param {boolean} isActive
 * @returns {string}
 */
function menuItemClass(isActive) {
  return isActive ? withModifier('header__menu__wide__list__item', 'active') : withModifier('header__menu__wide__list__item', 'disable');
}

/**
 * データの取得
 * @param {number} year
 * @returns {Object}
 */
async function fetchData(year) {
  const plans = await Plan.context({ year }).getVisibleDocs();
  plans.sort(Plan.compareForSort)
  const validDivisions = Object.values(Division)
  const data = defaultData()
  for (const plan of plans) {
    if (!validDivisions.includes(plan.division)) {
      continue
    }
    data[plan.division].push(plan)
  }
  return data;
}

export default {
  name: 'AppHeader',
  components: { AppLogo, CrossButton, MyLink, ItemMenu, PopupItemMenu },
  data() {
    return {
      data: defaultData(),
      isOpenDrawer: false,
      isLoading: true
    }
  },
  methods: {
    openDrawer() {
      this.isOpenDrawer = true;
    },
    closeDrawer() {
      this.isOpenDrawer = false;
    }
  },
  computed: {
    singleData() {
      return this.data[Division.Single]
    },
    setData() {
      return this.data[Division.Set]
    },
    featureData() {
      return this.data[Division.Feature]
    },
    hasSingle() {
      return this.data[Division.Single].length > 0
    },
    hasSet() {
      return this.data[Division.Set].length > 0
    },
    hasFeature() {
      return this.data[Division.Feature].length > 0
    },
    singleMenuItemClass() {
      return menuItemClass(this.data[Division.Single].length > 0);
    },
    setMenuItemClass() {
      return menuItemClass(this.data[Division.Set].length > 0);
    },
    featureMenuItemClass() {
      return menuItemClass(this.data[Division.Feature].length > 0);
    }
  },
  created() {
    fetchData((new Date()).getFullYear())
        .then(data => this.data = data)
        .catch(e => console.error(e))
        .finally(() => this.isLoading = false)
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $base-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc($content-space - 12px);
  height: $header-height;
  z-index: $header-z-index;
  &__logo {
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 100%;
    text-align: center;
  }
  &__menu {
    height: 100%;
    &__default {
      display: flex;
      align-items: center;
      padding: 0 12px;
      height: 100%;
      &__icon {
        cursor: pointer;
      }
      &__nav {
        position: fixed;
        display: flex;
        flex-direction: row;
        top: 0;
        right: 0;
        bottom: 0;
        width: 216px;
        overscroll-behavior: contain;
        overflow-y: scroll;
        background-color: $base-color;
        z-index: $drawer-z-index;
        &:after {
          content: "";
          width: 1px;
          height: calc(100vh + 1px);
        }
        &__wrap {
          width: 100%;
          &__block {
            padding: 32px 16px 56px 16px;
            &__item {
              &:not(:first-child) {
                margin-top: 16px;
              }
              &__link {
                font-size: 1.6rem;
              }
              &__menu {
                &:not(:first-child) {
                  margin-top: 16px;
                }
              }
            }
          }
        }
      }
      &__close {
        position: fixed;
        bottom: 8px;
        right: 12px;
        width: 48px;
        height: 48px;
        z-index: calc(#{$drawer-z-index} + 1);
      }
      &__background {
        position: fixed;
        display: flex;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overscroll-behavior: contain;
        overflow-y: scroll;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: calc(#{$drawer-z-index} - 1);
        &:after {
          content: "";
          width: 1px;
          height: calc(100vh + 1px);
        }
      }
    }
    &__wide {
      display: none;
      height: 100%;
      &__list {
        display: flex;
        flex-direction: row;
        height: 100%;
        &__item {
          position: relative;
          padding: 0 16px;
          height: 100%;
          line-height: $header-height;
          font-size: 1.6rem;
          &--active {
            color: $sub-color;
            cursor: pointer;
            &:hover {
              color: $base-color;
              background-color: $accent-color;
              &>.header__menu__wide__list__item__popup {
                display: initial;
              }
            }
          }
          &--disable {
            color: $font-color;
          }
          &__popup {
            display: none;
            position: absolute;
            top: 60px;
            left: 0;
            line-height: initial;
            padding: 16px 16px;
            width: 162px;
            box-shadow: 2px 2px 6px $shadow-color, -2px 2px 6px $shadow-color;
            /*border: 1px solid $accent-color;*/
            background-color: $base-color;
            cursor: default;
          }
        }
      }
    }
  }
}

.slide {
  &-enter-active, &-leave-active {
    transform: translateX(0px);
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  &-enter-from, &-leave-to {
    transform: translateX(100vw);
  }
}

@media screen and (min-width: $min-width-threshold) {

.header {
  padding: 0 calc($content-space-wide - 12px);
  &__menu {
    &__default {
      display: none;
    }
    &__wide {
      display: flex;
    }
  }
}

}
</style>
