<template>
  <div class="info">
    <p class="info__text"><span class="info__text__label">お支払方法</span>{{ paymentMethod }}</p>
    <div v-if="isBankTransfer">
      <p class="info__text"><span class="info__text__label">支払期限</span>{{ peymentLimitAt }}</p>
      <MyNote class="info__banknote" :type="myNoteTypeKome" :data="bankNotes" />
      <section v-if="isBankTransfer" class="info__bank">
        <h2 class="info__bank__title">お振込先</h2>
        <div class="info__bank__content">
          <p class="info__bank__content__text">
            PayPay銀行 ビジネス営業部（005）<br>
            普通預金 3697220<br>
            口座名義 ワイエムジーティーヤマガタユウジ<br>
          </p>
        </div>
      </section>
    </div>
    <MyNote class="info__note" :type="myNoteTypeKome" :data="notes" />
  </div>
</template>

<script>
import { getPaymentType } from '../../utils/constants.js'
import { formatJpDate } from '../../utils/helper.js'
import MyNote, { ConstantsMixin as MyNoteConstantsMixin } from '../Atoms/MyNote.vue'

export default {
  mixins: [MyNoteConstantsMixin],
  name: 'PaymentInfo',
  components: { MyNote },
  props: {
    orderedAt: Date,
    paymentType: Number,
    confirmCode: String
  },
  computed: {
    paymentMethod() {
      const payment = getPaymentType(this.paymentType)
      return payment ? payment.name : ''
    },
    isBankTransfer() {
      const payment = getPaymentType(this.paymentType)
      return payment ? payment.id === 'BankTransfer' : false
    },
    peymentLimitAt() {
      const paymentLimitDays = getPaymentType(this.paymentType).paymentLimitDays;
      const paymentLimitAt = new Date(this.orderedAt.getTime() + paymentLimitDays * 24 * 60 * 60 * 1000)
      return formatJpDate(paymentLimitAt)
    },
    bankNotes() {
      return ['お振込の確認をスムーズにするため、ご入金の際の依頼人名をご注文者様の名前にしてくださいますようお願いいたします。']
    },
    notes() {
      const payment = getPaymentType(this.paymentType)
      if (!payment) {
        return []
      }
      if (payment.id === 'BankTransfer') {
        return [
          '振込手数料はお客さま負担となります。',
          '期限までにお振込みが確認できない場合、注文はキャンセル扱いとなります。'
        ]
      }
      if (payment.id === 'Cache') {
        return ['現金は商品引き渡し時にお支払いください。']
      }
      return []
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles';

.info {
  &__text {
    font-size: 2.0rem;
    color: $font-color;
    text-align: center;
    &__label {
      font-size: 2.0rem;
      color: $font-color;
      &:after {
        content: '：';
      }
    }
  }
  &__banknote {
    margin-top: 8px;
  }
  &__bank {
    margin-top: 16px;
    &__title {
      font-size: 1.7rem;
      color: $font-color;
    }
    &__content {
      border: 1px solid $main-color;
      margin-top: 4px;
      padding: 12px;
      &__text {
        font-size: 1.4rem;
        color: $font-color;
      }
    }
  }
  &__note {
    margin-top: 8px;
  }
}

@media screen and (min-width: $min-width-threshold) {
}
</style>
